import { connect } from "react-redux";
import Register from "./Register";
import { userRegister, userLogOut } from "../../redux/actions/auth";

const mapStatetoProps = state => {
  return {
    userCognito: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    userRegister: user => {
      dispatch(userRegister(user));
    },
    signOut: () => {
      dispatch(userLogOut());
    }
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(Register);
