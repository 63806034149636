import { connect } from "react-redux";

import ForceChangePasswordComponent from "./ForceChangePassword";
import { userSignIn, userLogOut } from "../../../redux/actions/auth";

const mapStatetoProps = state => {
  return {
    user: state.user,
    auth: state.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    userLoggedIn: user => {
      dispatch(userSignIn(user));
    },
    userLogOut: () => {
      dispatch(userLogOut());
    }
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(ForceChangePasswordComponent);
