import React, { useEffect, useState, Fragment } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Grid, Box } from "@material-ui/core/";
import Header from "../../navbarPublic/Header";
import {
  minLength,
  email,
  required,
  textField,
  messages,
  passwordMatch
} from "../../utils.auth";
import RenderLoading from "../../commons/loading/loading";

const style = {
  paper: {
    padding: "16px"
  },
  layout: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    fontSize: "32px",
    textAlign: "center",
    padding: "10px"
  },
  form: {
    width: "320px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  signUpButton: {
    margin: "32px",
    width: "80%"
  },
  field: {
    margin: "8px 32px"
  },
  error: {
    margin: "8px",
    color: "rgb(200,0,0)",
    height: 10
  },
  message: {
    margin: "8px",
    height: 10,
    fontSize: 12
  },
  validateTitle: {
    margin: "8px 32px",
    fontSize: "24px",
    textAlign: "center"
  }
};

const SignUp = props => {
  let { user, userSignupIn } = props;
  const history = useHistory();
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState(undefined);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      if (user && user.hasSignedUp === "SUCCESS_SIGNED") {
        history.push(`/confirm?email=${values.email}`);
      }
    },
    [errors, user, history, values.email]
  );
  const handleSubmit = funct => {
    if (required(values.email) === "Required") {
      return setErrors(messages.requireEmail);
    }
    if (required(values.password) === "Required") {
      return setErrors(messages.requirePassword);
    }
    if (required(values.passwordMatch) === "Required") {
      return setErrors("Field Repeat Password is require");
    }
    if (email(values.email) !== true) {
      return setErrors(email(values.email));
    }
    if (minLength(values.password) !== true) {
      return setErrors(minLength(values.password));
    }
    if (passwordMatch(values.password, values) !== true) {
      return setErrors(passwordMatch(values.password, values));
    }
    funct(values);
  };
  const signUp = async values => {
    try {
      setLoading(true);
      const send = {
        username: values.email,
        password: values.password,
        attributes: {
          email: values.email
        }
      };
      await Auth.signUp(send);
      setLoading(false);
      userSignupIn();
    } catch (error) {
      setLoading(false);
      setErrors(error.message);
    }
  };

  const signUpForm = () => {
    return (
      <div style={style.layout}>
        <div style={style.form}>
          {textField({
            disabled: loading,
            name: "email",
            label: "Correo",
            action: setValues,
            values
          })}
          {textField({
            disabled: loading,
            name: "password",
            label: "Contraseña",
            type: "password",
            action: setValues,
            values
          })}
          {textField({
            name: "passwordMatch",
            label: "Repetir Contraseña",
            type: "password",
            action: setValues,
            values
          })}
          <div style={style.message}>
            La contraseña debe incluir al menos una mayúscula, un número y
            carácter especial
          </div>
          <div style={style.error}>
            {errors && errors !== "undefined" ? errors : ""}
          </div>
          {loading ? (
            <RenderLoading />
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => handleSubmit(signUp)}
            >
              {"Crear cuenta"}
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <Grid item xs={12} md={12} lg={12}>
        <Header />
        <Grid>
          <Box p={6}>
            <div className={classes.paper}>
              <div style={style.title}>Crear cuenta</div>
              {signUpForm()}
              <br />
              <Button onClick={() => history.push("/login")}>
                Inciar sesión
              </Button>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  logo: {
    width: 64
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  button: {
    root: {
      color: "#e30a63"
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  signUp: {
    color: "signUp",
    cursor: "pointer"
  },
  navbar: {
    backgroundColor: "#efa707",
    height: 100,
    fontSize: 45,
    fontWeight: "bold",
    paddingLeft: 15,
    paddingTop: 10,
    color: "#174a7c",
    fontFamily: "aller-display"
  }
}));

export default SignUp;
