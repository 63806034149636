import React from "react";
//containers
import ContainerNavbar from "../components/navbar/ContainerNavbar";

import FarmacyContainer from "./farmacy";
import PacientesContainer from "./pacientes";
import LoginContainer from "./login";
import RegisterContainer from "./register";
import RecipeContainer from "./recipe";
import FarmaceutaContainer from "./farmaceuta";
import HomeContainer from "./home";
import AdminFarmaceutaContainer from "./adminfarmacy";
import SignUpContainer from "../components/awsAuth/SignUp";
import ConfirmContainer from "../components/awsAuth/Confirm";
import ForgotPasswordContainer from "../components/awsAuth/ForgotPassword";
import ChangePasswordContainer from "../components/awsAuth/ChangePassword";
import ForceChangePasswordContainer from "../components/awsAuth/ForceChangePassword/";

export const Pharmacy = () => (
  <ContainerNavbar component={() => <FarmacyContainer />} />
);
export const Pacientes = () => (
  <ContainerNavbar component={() => <PacientesContainer />} />
);
export const Login = () => <LoginContainer />;
export const SignUp = () => <SignUpContainer />;
export const Confirm = () => <ConfirmContainer />;
export const ForgotPassword = () => <ForgotPasswordContainer />;
export const ChangePassword = () => <ChangePasswordContainer />;
export const ForceChangePassword = () => <ForceChangePasswordContainer />;

export const Register = () => <RegisterContainer />;
export const Recipe = () => (
  <ContainerNavbar component={() => <RecipeContainer />} />
);
export const Farmaceuta = () => (
  <ContainerNavbar component={() => <FarmaceutaContainer />} />
);
export const Home = () => (
  <ContainerNavbar component={() => <HomeContainer />} />
);
export const AdminFarmaceuta = () => (
  <ContainerNavbar component={() => <AdminFarmaceutaContainer />} />
);
