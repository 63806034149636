import { connect } from "react-redux";

import Home from "./Home";

const mapStatetoProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(Home);
