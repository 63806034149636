import React from "react";
import TextField from "@material-ui/core/TextField";
import validator from "validator";
import { validateRut } from "@fdograph/rut-utilities";
export const required = value => (value ? true : "Required");

export const rutIsValid = rut => validateRut(rut);
export const email = value =>
  validator.isEmail(value) ? true : "Not Valid Email";

export const minLength = (value, length = 8) =>
  (value || "").length >= length ? true : "Min Length 8";

export const maxLength = (value, length = 160) => (value || "").length > length;

export const messages = {
  requireEmail: "Campo de correo es requerido",
  requirePassword: "Campo de contraseña es requerido",
  requireCode: "Campo de código es requerido",
  minLength: "Campo tiene que tener almenos 8 caráteres"
};

export const requireMessage = field => `Campo de ${field} es requerido`;

export const passwordMatch = (value, values) =>
  values.password !== values.passwordMatch ? "Passwords must match" : true;

export const translateMessageAuth = key => {
  let messages = {
    "Incorrect username or password.": "Correo o contraseña incorrecto",
    "Invalid verification code provided, please try again.":
      "Se proporcionó un código de verificación no válido. Vuelva a intentarlo."
  };

  return messages[key];
};
export const isFarmaceuta = user => {
  if (user && user.role_id === 4) {
    return true;
  }
  return false;
};
export const isAutenticate = user => {
  if (user && user.username) {
    return true;
  }
  return false;
};
export const isAdmin = user => {
  if (isAutenticate(user) && user.role_id === 1) {
    return true;
  }
  return false;
};

export const isRegister = user => {
  if (
    user &&
    user.roleId &&
    user.firstName &&
    user.lastName &&
    user.phoneNumber &&
    user.rut
  ) {
    return true;
  }
  return false;
};
export const getRol = user => {
  if (
    user &&
    user.roleId &&
    user.firstName &&
    user.lastName &&
    user.phoneNumber &&
    user.rut
  ) {
    return user.roleId;
  }
  return false;
};

export const textField = ({
  name,
  label,
  type,
  action,
  subAction,
  defaultValue = "",
  values,
  size = "medium",
  value,
  disabled
}) => (
  <TextField
    disabled={disabled}
    variant="outlined"
    margin="normal"
    required
    fullWidth
    id={name}
    label={label}
    name={name}
    type={type || "text"}
    autoComplete={name}
    onChange={({ target: { value } }) => action({ ...values, [name]: value })}
    onKeyDown={subAction}
    defaultValue={defaultValue}
    value={value}
    size={size}
  />
);
