import React, { Fragment, useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { Grid, Box } from "@material-ui/core/";
import { getModel, createModelJson } from "../../services/service.api";
import Button from "@material-ui/core/Button";
import { validateRut } from "@fdograph/rut-utilities";
import { email, required, messages, requireMessage } from "../utils.auth";
import InputMask from "react-input-mask";
import { responseError } from "../commons/errors/utils";
import RenderLoading from "../commons/loading/loading";

export default function Person(props) {
  const { setRegistrado, registrado, valuesUser, setValuesUser } = props;
  const [renderUser, setRenderUser] = useState(false);
  const [errors, setErrors] = useState(undefined);
  const [errorsRut, setErrorsRut] = useState(undefined);
  const [loadingForm, setLoadingForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSaveUser = async () => {
    try {
      setLoadingForm(true);
      const data = await createModelJson("peoples/user", {})({
        ...valuesUser,
        role_id: 3
      });
      setLoadingForm(false);
      setValuesUser({
        ...data.profile,
        ...data,
        paciente_id: data.id,
        role_id: 3
      });
      setRegistrado(true);
    } catch (error) {
      setLoadingForm(false);
      //mostrar alert con error interpretado en español
      console.log(error.response);
      setErrors(responseError(error));
    }
  };
  const handleInputChangeRut = async value => {
    if (!(value || "").includes("_")) {
      if (validateRut(value) === true) {
        try {
          setLoading(true);
          const { data: people } = await getModel("peoples", {
            params: { rut: value }
          });
          const { data: userdb } = await getModel("peoples/user", {
            params: { rut: value, role_id: 3 }
          });

          if (people && people.length > 0) {
            setValuesUser({ ...people[0] });
            if (!userdb) {
              setRenderUser(true);
            } else {
              setValuesUser({
                ...userdb[0],
                paciente_id: userdb[0].userInfo.id
              });
              setRegistrado(true);
            }
          } else {
            setValuesUser({ ...valuesUser, rut: value });
            setRenderUser(true);
            setErrorsRut("");
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      } else {
        setRenderUser(false);
        setValuesUser({ ...valuesUser, rut: value });
        if (value.length === 10) {
          setErrorsRut("Rut Invalido");
        } else {
          setErrorsRut("");
        }
      }
    } else {
      setErrorsRut("");
    }
  };

  const handleSubmit = funct => {
    if (required(valuesUser.rut) === "Required") {
      return setErrors(requireMessage("Rut"));
    }
    if (required(valuesUser.first_name) === "Required") {
      return setErrors(requireMessage("Nombre"));
    }
    if (required(valuesUser.last_name) === "Required") {
      return setErrors(requireMessage("Apellido"));
    }
    if (required(valuesUser.gender) === "Required") {
      return setErrors(requireMessage("Genero"));
    }
    if (required(valuesUser.email) === "Required") {
      return setErrors(messages.requireEmail);
    }
    if (email(valuesUser.email) !== true) {
      return setErrors("Campo de corre es invalido");
    }
    if (required(valuesUser.address) === "Required") {
      return setErrors(requireMessage("Direccion"));
    }
    if (required(valuesUser.phone) === "Required") {
      return setErrors(requireMessage("Telefono"));
    }
    if (valuesUser.phone.length < 14) {
      return setErrors("Campo Teléfono posee formato no valido");
    }
    funct(valuesUser);
  };

  return (
    <Fragment>
      <Box boxShadow={5}>
        <Box p={1} m={4}>
          <Grid>
            <Box textAlign="center">
              <h1>Datos del Paciente</h1>
            </Box>
          </Grid>
          {loading ? (
            <RenderLoading />
          ) : (
            <Grid container spacing={4} className={style.container}>
              <Grid item xs={12} md={!renderUser ? 12 : 6}>
                <InputMask
                  maskChar=""
                  mask="99999999-k"
                  formatChars={{
                    "9": "[0-9]",
                    k: "[0-9,k-k,K-K]"
                  }}
                  inputProps={{
                    minLength: 10,
                    maxLength: 10
                  }}
                  onChange={({ target: { value } }) => {
                    handleInputChangeRut(value);
                  }}
                  defaultValue={(valuesUser || {}).rut}
                >
                  {() => (
                    <TextField
                      id="rut"
                      label="RUT"
                      name="rut"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      fullWidth
                      autoFocus
                    />
                  )}
                </InputMask>
                <div style={style.error}>{errorsRut && errorsRut}</div>
              </Grid>
              {renderUser && (
                <>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="first_name"
                      name="first_name"
                      label="Nombre"
                      fullWidth
                      inputProps={{ maxLength: 50 }}
                      defaultValue={valuesUser.first_name}
                      onChange={({ target: { value } }) =>
                        setValuesUser({ ...valuesUser, first_name: value })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="last_name"
                      name="last_name"
                      label="Apellido"
                      fullWidth
                      inputProps={{ maxLength: 50 }}
                      value={valuesUser.last_name}
                      onChange={({ target: { value } }) =>
                        setValuesUser({ ...valuesUser, last_name: value })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} container>
                    <Select
                      labelId="gender"
                      id="gender"
                      name="gender"
                      fullWidth
                      defaultValue={0}
                      value={valuesUser.gender || 0}
                      onChange={({ target: { value } }) =>
                        setValuesUser({ ...valuesUser, gender: value })
                      }
                    >
                      <MenuItem value={0}>Genero</MenuItem>
                      <MenuItem value="Female">Femenino</MenuItem>
                      <MenuItem value="Male">Masculino</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="email"
                      name="email"
                      label="Email"
                      fullWidth
                      value={valuesUser.email || ""}
                      inputProps={{ maxLength: 50 }}
                      onChange={({ target: { value } }) =>
                        setValuesUser({ ...valuesUser, email: value })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputMask
                      mask="+56 9 99999999"
                      maskChar=" "
                      value={valuesUser.phone || ""}
                      onChange={({ target: { value } }) =>
                        setValuesUser({ ...valuesUser, phone: value.trim() })
                      }
                    >
                      {() => (
                        <TextField
                          margin="dense"
                          fullWidth
                          id="phone"
                          label="Telefono"
                          name="phone"
                          autoComplete="phone"
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="address"
                      name="address"
                      label="Direccion"
                      value={valuesUser.address || ""}
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      onChange={({ target: { value } }) =>
                        setValuesUser({ ...valuesUser, address: value })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div style={style.error}>
                      {errors && errors !== "undefined" ? errors : ""}
                    </div>
                  </Grid>
                  {loadingForm ? (
                    <RenderLoading />
                  ) : (
                    <>
                      <Grid item xs={6} md={6}>
                        <Button
                          type="submit"
                          style={{ borderRadius: 10 }}
                          color="primary"
                          variant="contained"
                          onClick={() =>
                            !registrado ? setRenderUser(false) : () => {}
                          }
                          fullWidth
                        >
                          Atras
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Button
                          type="submit"
                          style={{ borderRadius: 10 }}
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={() => handleSubmit(handleSaveUser)}
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          )}
        </Box>
      </Box>
    </Fragment>
  );
}
const style = {
  error: {
    width: "95%",
    margin: "8px",
    fontSize: "13px",
    color: "rgb(200,0,0)"
  }
};
