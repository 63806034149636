import React, { useEffect, useState, Fragment } from "react";
import { getModel } from "../../../services/service.api";
import { Auth } from "aws-amplify";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Grid, Box } from "@material-ui/core/";
import Header from "../../navbarPublic/Header";
import {
  minLength,
  email,
  required,
  textField,
  messages
} from "../../utils.auth";
import RenderLoading from "../../commons/loading/loading";
import { responseError } from "../../commons/errors/utils";

const style = {
  paper: {
    padding: "16px",
    width: "100%"
  },
  layout: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    fontSize: "32px",
    textAlign: "center"
  },
  form: {
    width: "320px",
    display: "flex",
    flexFlow: "column",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  signup: {
    fontSize: "14px",
    marginBottom: "16px"
  },
  signInButton: {
    marginBottom: "16px",
    width: "80%"
  },
  button: {
    margin: "8px 0"
  },
  error: {
    width: "95%",
    margin: "8px",
    fontSize: "13px",
    color: "blue"
  }
};

const SignIn = props => {
  const { user, userLogOut } = props;
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState(undefined);
  const history = useHistory();
  const classes = useStyles();
  let location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      const redirect = urlParams.get("redirect");
      if (user && user.username) {
        if (!user.rut) {
          return history.push("/register");
        }
        return history.push(!redirect ? "/home" : redirect);
      }
      if (user && !user.auth) {
        userLogOut();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, history, userLogOut, errors]
  );

  const signIn = async () => {
    try {
      setLoading(true);
      const auth = await Auth.signIn(values.email, values.password);
      if (auth.challengeName === "NEW_PASSWORD_REQUIRED") {
        props.userLoggedIn({ auth });
        return history.push(`/forceChangePassword?email=${values.email}`);
      }

      let user = await Auth.currentAuthenticatedUser();

      const { data: userdb } = await getModel("users/people", {
        params: { cognito_id: user.attributes.sub }
      });
      if (userdb && userdb.length) {
        user = {
          ...user,
          ...userdb[0].profile,
          user_id: userdb[0].id,
          role_id: userdb[0].role_id,
          pharmacy_id: userdb[0].pharmacy_id
        };
      }
      setLoading(false);

      props.userLoggedIn(user);
    } catch (error) {
      setLoading(false);
      console.log(error);
      setErrors(responseError(error));
    }
  };

  const handleSubmit = funct => {
    if (required(values.email) === "Required") {
      return setErrors(messages.requireEmail);
    }
    if (required(values.password) === "Required") {
      return setErrors(messages.requirePassword);
    }
    if (email(values.email) !== true) {
      return setErrors(email(values.email));
    }
    if (minLength(values.password) !== true) {
      return setErrors(minLength(values.password));
    }
    funct(values);
  };

  const getMessageUrl = () => {
    if (urlParams.get("confirm") === "true") {
      return (
        <div style={style.info}>
          Su correo ha sido verificado satisfactoriamente
        </div>
      );
    }
    if (urlParams.get("changePass") === "true") {
      return (
        <div style={style.info}>
          Su contraseña a sido modificada satisfactoriamente
        </div>
      );
    }
    if (urlParams.get("changePass") === "expired") {
      return (
        <div style={style.info}>
          La sesión ha exprirado debe comenzar de nuevo el proceso, cambio de
          contraseña
        </div>
      );
    }
    return null;
  };
  const renderSignIn = () => (
    <div style={style.layout}>
      <div style={style.form}>
        {getMessageUrl()}
        {textField({
          disabled: loading,
          name: "email",
          label: "Correo",
          action: setValues,
          values
        })}
        {textField({
          disabled: loading,
          name: "password",
          label: "Contraseña",
          type: "password",
          action: setValues,
          values,
          subAction: e => {
            if (e.key === "Enter") {
              handleSubmit(signIn);
            }
          }
        })}
        <div style={style.error}>
          {errors && errors !== "undefined" ? errors : ""}
        </div>
        {loading ? (
          <RenderLoading />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => handleSubmit(signIn)}
          >
            {"Ingresar"}
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <Fragment>
      <Grid item xs={12} md={12} lg={12}>
        <Header />
        <Grid>
          <Box p={6}>
            <div className={classes.paper}>
              <div style={style.title}>Iniciar Sesión</div>
              {renderSignIn()}
              <div className={classes.redirets}>
                Si no tienes cuenta
                <Button
                  onClick={() => history.push("/signup")}
                  color={"primary"}
                >
                  créala aquí
                </Button>
                <br />
                Olvidé mi
                <Button
                  onClick={() => history.push("/forgotPassword")}
                  color={"primary"}
                >
                  contraseña
                </Button>
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};
const useStyles = makeStyles(theme => ({
  logo: {
    width: 64
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  button: {
    root: {
      color: "#e30a63"
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  signUp: {
    color: "signUp",
    cursor: "pointer"
  },
  navbar: {
    backgroundColor: "#efa707",
    height: 100,
    fontSize: 45,
    fontWeight: "bold",
    paddingLeft: 15,
    paddingTop: 10,
    color: "#174a7c",
    fontFamily: "aller-display"
  }
}));

export default SignIn;
