import React, { Fragment, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { useHistory } from "react-router-dom";
import {
  getModel,
  createModelJson,
  delModel
} from "../../services/service.api";

import {
  TextField,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from "@material-ui/core/";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ConfirmDialog from "./ConfirmDialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import { makeStyles, Box, Grid, Snackbar, Paper } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import RenderLoading from "../commons/loading/loading";
import moment from "moment";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const ListFarmaceuta = props => {
  let history = useHistory();
  let location = useLocation();
  const [loading, setLoading] = useState(false);
  const { user } = props;
  const classes = useStyles();
  const [dataRecipe, setDataRecipe] = useState({});
  const [dataTransaction, setdataTransaction] = useState({});
  const [showAlert, setShowAlert] = useState({ open: false });
  const [showAlertLock, setShowAlertLock] = useState(false);
  const [dataFrecuency, setdataFrecuency] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [enableButton, setenableButton] = useState(0);
  const [confirmOpen, setconfirmOpen] = useState({});
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const closeRecipe = async () => {
    setdataTransaction({});
    await delModel("recipepharmacist")(dataTransaction.id);
    history.push("/farmaceutico");
  };

  const getRecipe = async token => {
    try {
      setLoading(true);
      const { data: isData } = await getModel("recipes/pdf/token", {
        params: { token }
      });
      if (isData && isData.signed_status !== 3) {
        return;
      }
      const { data } = await getModel("recipes", {
        params: { token, signed_status: 3 }
      });
      if (data && !dataTransaction.length) {
        const data_last = await getModel("recipepharmacist", {
          params: {
            recipe_id: data[0].id,
            pharmacist_id: user.user_id,
            status: "read"
          }
        }).then(async () => {
          const pharmacist_delivery = await createModelJson(
            "recipepharmacist",
            {}
          )({
            recipe_id: data[0].id,
            pharmacist_id: user.user_id,
            status: "read"
          });
          return pharmacist_delivery;
        });
        setdataTransaction(data_last);
      }

      await getModel("recipepharmacist/last", {
        params: {
          token: token,
          status: "read",
          pharmacist_id: user.user_id
        }
      }).then(result => {
        if (result.data && result.data.pharmacist_id !== user.user_id) {
          setShowAlertLock(true);
        } else {
          setShowAlertLock(false);
        }
      });
      if (data) {
        setDataRecipe(data[0]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleClose = () => {
    setShowAlertLock(false);
  };

  const getDataFrec = async () => {
    try {
      const response = await getModel("frequency", {});
      const { data } = await response;
      if (data) {
        setdataFrecuency(data.map(i => ({ value: i.id, name: i.name })));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const ConfirmMedicine = async row => {
    try {
      if (
        quantity[row.id] > row.quantity - row.quantity_delivery ||
        quantity[row.id] < 1
      ) {
        setShowAlert({
          open: true,
          message: "Cantidad erronea!",
          type: "warning"
        });
        return;
      }
      await createModelJson("medicinestatusdelivery", {})({
        recipe_medicine_id: row.id,
        quantity: quantity[row.id],
        pharmacy_id: user.pharmacy_id,
        pharmacist_id: user.user_id,
        status_delivery_id: quantity[row.id] === row.quantity ? 3 : 2
      });

      setShowAlert({
        open: true,
        message: "Cantidad actualizada Satisfactoriamente!",
        type: "success"
      });
      setQuantity({ ...quantity, [`${row.id}`]: "0" });
      setenableButton({});
    } catch (error) {
      setShowAlert({
        open: true,
        message: error.message,
        type: "error"
      });
    }
  };
  const intervalGetRecipeByFarmaceuta = async () => {
    const { data } = await getModel("recipepharmacist/ping", {
      params: {
        status: "read",
        pharmacist_id: user.user_id
      }
    });

    if (data && data.recipe && data.recipe.token) {
      await getModel("recipes/pdf/token", {
        params: { token: data.recipe.token }
      });
      history.push(`/farmaceutico?token=${data.recipe.token}`);
    }
  };
  useEffect(
    () => {
      if (user.pharmacy_id === null) {
        history.push("/login");
      }
      var searchParams = new URLSearchParams(location.search);
      const token = searchParams.get("token");
      if (token && !Object.values(enableButton).length) {
        getRecipe(token);
        getDataFrec();
      } else {
        const timer = setInterval(() => {
          intervalGetRecipeByFarmaceuta();
        }, 5000);
        // clearing interval
        return () => clearInterval(timer);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, enableButton, user, showAlertLock]
  );
  return loading ? (
    <RenderLoading />
  ) : !dataRecipe.id ? (
    <Box p={1} m={4}>
      <center>
        <img
          src={"prescription.png"}
          style={{ width: "100%", maxWidth: 300 }}
          alt={"receta"}
        />
        <h1>No se encontraron recetas</h1>
        <p>Escanear Qr</p>
      </center>
    </Box>
  ) : (
    <Fragment>
      <Box boxShadow={5}>
        <Box p={1} m={4}>
          <Grid>
            <Box display="flex" p={4}>
              <Box p={1} flexGrow={1}>
                <h1>Receta: {dataRecipe.token.split("-")[0]} </h1>
              </Box>
              <Box mr={2}>
                <p>
                  Fecha de Emisión:{" "}
                  {moment(dataRecipe.date_broadcast, "YYYY-MM-DD").format(
                    " MMMM D Y"
                  )}
                </p>
                {/* <p>Validador: {token}</p> */}
              </Box>
            </Box>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Paciente</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr align="left">
                      <Td>Nombre:</Td>
                      <Td>
                        {dataRecipe.paciente
                          ? dataRecipe.paciente.profile.first_name +
                            " " +
                            dataRecipe.paciente.profile.last_name
                          : ""}
                      </Td>
                    </Tr>
                    <Tr align="left">
                      <Td>RUT:</Td>
                      <Td>{(dataRecipe.paciente.profile || {}).rut}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Medico</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr align="left">
                      <Td>Nombre:</Td>
                      <Td>
                        {dataRecipe.doctor
                          ? dataRecipe.doctor.profile.first_name
                          : ""}{" "}
                        {dataRecipe.doctor
                          ? dataRecipe.doctor.profile.last_name
                          : ""}
                      </Td>
                    </Tr>
                    <Tr align="left">
                      <Td>RUT:</Td>
                      <Td>
                        {dataRecipe.doctor ? dataRecipe.doctor.profile.rut : ""}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Table>
                  <Thead>
                    <Tr align="left">
                      <Th style={{ width: 300 }}>Medicamento</Th>
                      <Th>Dosis</Th>
                      <Th>Frecuencia</Th>
                      <Th>Duración</Th>
                      <Th>Cantidad</Th>
                      <Th>Observacion</Th>
                      <Th style={{ width: 150 }}>Cantidad a entregar</Th>
                      <Th>Entregar</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dataRecipe.doctor
                      ? dataRecipe.Medicines.map((row, key) => (
                          <Tr align="left" key={key}>
                            <Td>{row.medicine}</Td>
                            <Td>{row.dose_value}</Td>
                            <Td>
                              {row.frequency_value + " "}
                              {dataFrecuency
                                ? //  ? dataFrecuency[row.frequency_id].name
                                  dataFrecuency.find(
                                    obj => obj.value === row.frequency_id
                                  )
                                  ? dataFrecuency.find(
                                      obj => obj.value === row.frequency_id
                                    ).name
                                  : ""
                                : null}
                            </Td>
                            <Td>
                              {row.duration + " "}
                              {dataFrecuency
                                ? dataFrecuency.find(
                                    obj => obj.value === row.duration_frequency
                                  )
                                  ? dataFrecuency.find(
                                      obj =>
                                        obj.value === row.duration_frequency
                                    ).name
                                  : ""
                                : null}
                            </Td>
                            <Td>
                              {row.quantity - row.quantity_delivery === 0
                                ? row.quantity
                                : row.quantity - row.quantity_delivery}
                            </Td>
                            <Td>{row.observation}</Td>
                            <Td>
                              {row.quantity - row.quantity_delivery === 0 ? (
                                "Entregado(s)"
                              ) : (
                                <TextField
                                  id={`${row.id}-quantity`}
                                  name={`${row.id}-quantity`}
                                  fullWidth
                                  type="number"
                                  inputProps={{
                                    min: 0,
                                    max: parseFloat(
                                      row.quantity - row.quantity_delivery
                                    ),
                                    style: { textAlign: "right" }
                                  }}
                                  onBlur={() => {
                                    if (
                                      quantity[`${row.id}`] >
                                        row.quantity - row.quantity_delivery ||
                                      quantity[`${row.id}`] < 0
                                    ) {
                                      setShowAlert({
                                        open: true,
                                        message: `Cantidad errada para ${
                                          row.medicine
                                        }`,
                                        type: "error"
                                      });
                                      setenableButton({});
                                    } else {
                                      if (quantity[`${row.id}`] > 0) {
                                        setenableButton({
                                          ...enableButton,
                                          [`${row.id}`]: true
                                        });
                                      }
                                    }
                                  }}
                                  onChange={({ target: { value } }) => {
                                    setQuantity({
                                      ...quantity,
                                      [`${row.id}`]: value
                                    });
                                    if (value > 0) {
                                      setenableButton({
                                        ...enableButton,
                                        [`${row.id}`]: true
                                      });
                                    } else {
                                      setenableButton({
                                        ...enableButton,
                                        [`${row.id}`]: false
                                      });
                                    }
                                  }}
                                  value={quantity ? quantity[row.id] : null}
                                />
                              )}
                            </Td>
                            <Td>
                              <IconButton
                                disabled={!enableButton[row.id]}
                                id={`${row.id}-button`}
                                name={`${row.id}-button`}
                                color="secondary"
                                aria-label="Entregar"
                                onClick={() => {
                                  setconfirmOpen({
                                    ...confirmOpen,
                                    [`${row.id}`]: true
                                  });
                                }}
                              >
                                <CheckCircleOutlineIcon />
                              </IconButton>
                              <ConfirmDialog
                                title={row.medicine}
                                open={row ? confirmOpen[row.id] : false}
                                row={row}
                                setconfirmOpen={setconfirmOpen}
                                ConfirmMedicine={ConfirmMedicine}
                              >
                                ¿Estás seguro de entregar la cantidad de{" "}
                                {quantity[row.id]} para este medicamento?
                              </ConfirmDialog>
                            </Td>
                          </Tr>
                        ))
                      : null}
                  </Tbody>
                </Table>
              </Paper>

              <Button
                variant="outlined"
                color="primary"
                className={classes.buttonStyle}
                onClick={() => {
                  closeRecipe();
                }}
              >
                Cerrar receta
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Snackbar
        open={showAlert.open}
        autoHideDuration={6000}
        onClose={() => setShowAlert({})}
      >
        <Alert onClose={() => setShowAlert({})} severity={showAlert.type}>
          {showAlert.message}
        </Alert>
      </Snackbar>
      <Dialog
        fullScreen={fullScreen}
        open={showAlertLock}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Advertencia"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Actualmente la receta no puede ser modificada; ya otro farmacéutico
            posee el control de la misma.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

const useStyles = makeStyles(() => ({
  grow: {
    flexGrow: 1
  },
  buttonStyle: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: "auto",
    float: "right"
  }
}));

export default ListFarmaceuta;
