import React from "react";
//containers
import ContainerNavbar from "../../components/navbar/ContainerNavbar";

import AdminMangersContainers from "./Managers";

export const AdminManagers = () => (
  <ContainerNavbar
    component={() => (
      <AdminMangersContainers
        {...{ ROLE_ID: 5, LABEL: "Manager Farmacéuticos" }}
      />
    )}
  />
);

export const AdminMedicos = () => (
  <ContainerNavbar
    component={() => (
      <AdminMangersContainers {...{ ROLE_ID: 2, LABEL: "Médico" }} />
    )}
  />
);

export const AdminAdmins = () => (
  <ContainerNavbar
    component={() => (
      <AdminMangersContainers {...{ ROLE_ID: 1, LABEL: "Administrador" }} />
    )}
  />
);

export const AdminFarmaceuticos = () => (
  <ContainerNavbar
    component={() => (
      <AdminMangersContainers {...{ ROLE_ID: 4, LABEL: "Farmaceuticos" }} />
    )}
  />
);
