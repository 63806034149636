import { createMuiTheme } from "@material-ui/core/styles";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#140126",
      light: "#04BCD9",
      dark: "#008051",
      contrastText: "#fff"
    },
    secondary: {
      main: "#04D98B"
    },
    tertiary: {
      main: "#04D98B"
    }
  }
});

export default theme;
