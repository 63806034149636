import React, { useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";

export default function Home(props) {
  let history = useHistory();
  const { user } = props;

  useEffect(
    () => {
      if (user && user.role_id === 5) {
        history.push("/adminfarma");
      }
      if (user && user.role_id === 2) {
        history.push("/pacientes");
      }
      if (user && user.role_id === 4) {
        history.push("/farmaceutico");
      }
      if (user && user.role_id === 1) {
        history.push("/farmacias");
      }
    },
    [history, user]
  );
  return <Fragment>Opcion no disponible</Fragment>;
}
