import React, { Fragment } from "react";

import Login from "../components/awsAuth/SignIn";

const login = () => {
  return (
    <Fragment>
      <Login />
    </Fragment>
  );
};

export default login;
