import { defaultUser } from "../config/config";
import { API } from "aws-amplify";

const setParams = (url, params) => {
  let withToken = url;
  if (params && Object.keys(params).length) {
    const queryParams = Object.keys(params).reduce(
      (par, key) => (par += `&${key}=${params[key]}`),
      "?"
    );
    withToken = withToken + queryParams;
  }
  return withToken;
};

const get = async (model, options) => {
  const { id, params } = options || {};
  try {
    const data = await API.get(
      "TocApi",
      setParams(`/${model}${id ? "/" + id : ""}`, params)
    );
    return { data };
  } catch (error) {
    return { data: [] };
  }
};

const edit = model => (id, properties) => {
  return API.patch("TocApi", setParams(`/${model}/${id}`), {
    method: "PATCH",
    body: properties
  });
};

const create = model => properties => {
  return API.post("TocApi", setParams(`/${model}`), {
    body: properties
  });
};

const del = model => async itemId =>
  await API.del("TocApi", setParams(`/${model}/${itemId}`));

export const getModel = get;
export const delModel = del;
export const createModel = create;

export const createModelJson = model => properties => {
  return API.post("TocApi", setParams(`/${model}`), {
    body: properties
  });
};

export const editModel = (model, options = {}) => (id, properties) => {
  return edit(model, options)(id, properties);
};

export const registerUser = (user, options = {}) =>
  createModelJson("users/register", options)({
    ...defaultUser,
    ...user
  });
