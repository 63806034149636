import React, { useState, Fragment, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Grid, Tooltip } from "@material-ui/core/";
import { getModel } from "../../services/service.api";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import "../../App.css";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "90%"
    }
  }
}));

export default function Medicina(props) {
  const { valuesRecipe, setValuesRecipe, index, deleteMedicine } = props;
  const classes = useStyles();
  const [top100Films, setTop100Films] = useState([]);
  const [frequency, setFrequency] = useState([]);

  const handleInputMedicina = async e => {
    handlesetValues(index)("medicine", e.target.value);
    if (e.target.value.length >= 2) {
      try {
        let url = new URL(
            "https://gtw-prod.alemana.io/public/api-farmacos/v1/amp"
          ),
          params = { q: e.target.value, refset: "1002,1003,1004,1005,1006" };
        Object.keys(params).forEach(key =>
          url.searchParams.append(key, params[key])
        );

        let response = await fetch(url, {
          method: "GET",
          headers: {
            "X-API-Key":
              "5e1dc5891a05f000011faf5225cf15abd7c34b5b918644f89428248a"
          }
        });
        if (response.ok) {
          let datos = await response.json();
          setTop100Films(datos._embedded.amp);
        } else {
          throw new Error(response.statusText);
        }
      } catch (err) {
        console.log("Error al realizar la petición: " + err.message);
      }
    } else {
      if (e.target.value.length < 2) {
        top100Films.splice(0, top100Films.length);
      }
    }
  };

  const getFrequency = async () => {
    const { data } = await getModel("frequency", {});
    setFrequency(data);
  };

  useEffect(() => {
    getFrequency();
  }, []);
  const handlesetValues = index => (name, value) => {
    const others = (valuesRecipe.medicinesArray || []).filter(
      (item, i) => i !== index
    );
    const edit =
      valuesRecipe.medicinesArray && valuesRecipe.medicinesArray[index]
        ? (valuesRecipe.medicinesArray[index] = {
            ...valuesRecipe.medicinesArray[index],
            [name]: value
          })
        : { [name]: value };

    setValuesRecipe({ ...valuesRecipe, medicinesArray: [...others, edit] });
  };

  const handleChange = index => ({ target: { name, value } }) =>
    handlesetValues(index)(name, value);
  return (
    <Fragment>
      <Grid
        justify="space-between" // Add it here :)
        container
        spacing={2}
      >
        <Grid item>
          <Typography className={classes.heading}>Medicina </Typography>
        </Grid>
        {valuesRecipe.medicinesArray.length > 1 && (
          <Grid item>
            <Tooltip title="Eliminar medicina">
              <DeleteIcon onClick={deleteMedicine} />
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={4} className={classes.container}>
        <Grid item xs={12} md={12}>
          <Autocomplete
            freeSolo
            id="medicine"
            name={"medicine"}
            options={top100Films}
            getOptionLabel={option => option.term}
            fullWidth
            onChange={(_, newValue) =>
              handlesetValues(index)("medicine", newValue.term)
            }
            renderInput={params => (
              <TextField
                label="Medicina"
                {...params}
                onChange={handleInputMedicina}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} container>
          <TextField
            id="dose_value"
            name={"dose_value"}
            label="Dosis"
            fullWidth
            inputProps={{ maxLength: 50 }}
            defaultValue={
              ((valuesRecipe["medicinesArray"] || [])[index] || {})[
                "dose_value"
              ]
            }
            onChange={handleChange(index)}
          />
        </Grid>

        <Grid item xs={12} md={6} container>
          <TextField
            id="quantity"
            name={"quantity"}
            label="Cantidad"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            fullWidth
            defaultValue={
              ((valuesRecipe["medicinesArray"] || [])[index] || {})["quantity"]
            }
            onChange={handleChange(index)}
          />
        </Grid>

        <Grid item xs={12} md={4} container>
          <TextField
            id="frequency_value"
            name={"frequency_value"}
            label="Frecuencia"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            fullWidth
            onChange={({ target: { value } }) =>
              handlesetValues(index)("frequency_value", value)
            }
            defaultValue={
              ((valuesRecipe["medicinesArray"] || [])[index] || {})[
                "frequency_value"
              ]
            }
          />
        </Grid>
        <Grid item xs={12} md={8} container>
          <Select
            labelId="Frequency"
            id="frequency"
            name={`frequency_id`}
            type="number"
            fullWidth
            defaultValue={
              ((valuesRecipe["medicinesArray"] || [])[index] || {})[
                "frequency_id"
              ] || 0
            }
            onChange={handleChange(index)}
          >
            <MenuItem value={0} disabled>
              Seleccionar tipo de frecuencia
            </MenuItem>
            {frequency.map((row, key) => (
              <MenuItem value={row.id} key={key}>
                {row.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={4} container>
          <TextField
            id="duration"
            name={"duration"}
            type="number"
            InputProps={{ inputProps: { min: 1 } }}
            label="Duración"
            fullWidth
            inputProps={{ maxLength: 50 }}
            defaultValue={
              ((valuesRecipe["medicinesArray"] || [])[index] || {})["duration"]
            }
            onChange={handleChange(index)}
          />
        </Grid>
        <Grid item xs={12} md={8} container>
          <Select
            id="duration_frequency"
            name={`duration_frequency`}
            type="number"
            fullWidth
            label="Duración"
            defaultValue={
              ((valuesRecipe["medicinesArray"] || [])[index] || {})[
                "duration_frequency"
              ] || 0
            }
            onChange={handleChange(index)}
          >
            <MenuItem value={0} disabled>
              Seleccionar tipo de duración
            </MenuItem>
            {frequency.map((row, key) => (
              <MenuItem value={row.id} key={key}>
                {row.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            id="observation"
            name={`observation`}
            label="Observacion"
            multiline
            rows={3}
            fullWidth
            inputProps={{ maxLength: 200 }}
            defaultValue={
              ((valuesRecipe["medicinesArray"] || [])[index] || {})[
                "observation"
              ]
            }
            placeholder="Indicar información del tratamiento."
            onChange={handleChange(index)}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
