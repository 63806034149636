import React, { useEffect, useState, Fragment } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Auth } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Grid, Box } from "@material-ui/core/";
import Header from "../../navbarPublic/Header";
import RenderLoading from "../../commons/loading/loading";
import { email, required, textField } from "../../utils.auth";

const ResendConfirm = props => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState(undefined);
  const classes = useStyles();
  let location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlEmail = urlParams.get("email");
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      const getData = async () => {
        try {
          if (urlEmail !== undefined && urlEmail !== "") {
            setValues({ email: urlEmail.replace(" ", "+") });
          }
        } catch (error) {
          console.log("error", error);
        }
      };
      getData();
    },
    [props, urlEmail]
  );

  const confirmCode = async values => {
    try {
      setLoading(true);
      await Auth.forgotPassword(values.email.toLowerCase());
      setLoading(false);
      history.push(`/changePassword?email=${values.email.toLowerCase()}`);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSubmit = funct => {
    if (required(values.email) === "Required") {
      return setErrors("Field email is require");
    }
    if (email(values.email) !== true) {
      return setErrors(email(values.email));
    }
    funct(values);
  };

  const renderConfirm = () => (
    <div style={style.layout}>
      <div style={style.form}>
        {textField({
          disabled: loading,
          name: "email",
          label: "Correo",
          action: setValues,
          values,
          value: values.email || ""
        })}
        <div style={style.error}>
          {errors && errors !== "undefined" ? errors : ""}
        </div>
        {loading ? (
          <RenderLoading />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => handleSubmit(confirmCode)}
          >
            {"Enviar Códigos"}
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <Fragment>
      <Grid item xs={12} md={12} lg={12}>
        <Header />
        <Grid>
          <Box p={10}>
            <div className={classes.paper}>
              <div style={style.title}>Olvidó Contraseña</div>
              {renderConfirm()}
              <div className={classes.redirets}>
                Ya tu cuenta esta verificada?
                <Button
                  onClick={() => history.push("/login")}
                  color={"primary"}
                >
                  Iniciar Sesión
                </Button>
                <br />
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const style = {
  paper: {
    padding: "16px"
  },
  layout: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    fontSize: "28px",
    textAlign: "center"
  },
  form: {
    width: "320px",
    display: "flex",
    flexFlow: "column",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  signup: {
    fontSize: "14px",
    marginBottom: "16px"
  },
  signInButton: {
    marginBottom: "16px",
    width: "80%"
  },
  button: {
    margin: "8px 0"
  },
  error: {
    width: "95%",
    margin: "8px",
    color: "rgb(200,0,0)"
  }
};
const useStyles = makeStyles(theme => ({
  logo: {
    width: 64
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  redirets: {
    marginTop: 30
  }
}));

export default ResendConfirm;
