import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  textField,
  required,
  messages,
  minLength,
  translateMessageAuth
} from "../../utils.auth";
import AWS from "aws-sdk";
import { responseError } from "../../commons/errors/utils";
import Header from "../../navbarPublic/Header";

const ResendConfirm = props => {
  const { user } = props;
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState(undefined);
  const classes = useStyles();
  let location = useLocation();
  const history = useHistory();
  useEffect(
    () => {
      const urlParams = new URLSearchParams(location.search);
      const urlEmail = urlParams.get("email");
      if (urlEmail) {
        setValues({ ...values, email: urlEmail.replace(" ", "+") });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location, errors]
  );

  const confirmCode = async values => {
    try {
      const identityProvider = new AWS.CognitoIdentityServiceProvider({
        region: "us-east-2"
      });
      const params = {
        ChallengeName: "NEW_PASSWORD_REQUIRED",
        ClientId: user.auth.pool.clientId,
        ChallengeResponses: {
          USERNAME: values.email.toLowerCase(),
          NEW_PASSWORD: values.password
        },
        Session: user.auth.Session
      };
      identityProvider.respondToAuthChallenge(params, (err, data) => {
        if (err) {
          if (
            err.message &&
            err.message.includes(
              "Invalid session for the user, session is expired."
            )
          ) {
            history.push("/login?changePass=expired");
          }
          return setErrors(responseError(err));
        } else {
          console.log(data);
          props.userLogOut();
          history.push("/login?changePass=true");
        }
      });
    } catch (error) {
      console.log(error);
      setErrors(translateMessageAuth(error.message));
    }
  };

  const handleSubmit = funct => {
    if (required(values.email) === "Required") {
      return setErrors(messages.requireEmail);
    }
    if (required(values.password) === "Required") {
      return setErrors(messages.requirePassword);
    }
    if (minLength(values.password) !== true) {
      return setErrors(minLength(values.password));
    }
    if (required(values.repeatpassword) === "Required") {
      return setErrors(messages.requirePassword);
    }
    if (minLength(values.repeatpassword) !== true) {
      return setErrors(minLength(values.oldPassword));
    }
    if (values.repeatpassword !== values.password) {
      return setErrors("Las contraseñas deben coincidir");
    }
    funct(values);
  };

  const renderConfirm = () => (
    <div style={style.layout}>
      <div style={style.form}>
        {textField({
          name: "email",
          label: "Correo",
          action: setValues,
          value: values.email || "",
          values
        })}
        {textField({
          name: "password",
          label: "Contraseña",
          action: setValues,
          type: "password",
          values
        })}
        {textField({
          name: "repeatpassword",
          label: "Repetir Contraseña",
          action: setValues,
          type: "password",
          values
        })}
        <div style={style.error}>
          {errors && errors !== "undefined" ? errors : ""}
        </div>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => handleSubmit(confirmCode)}
        >
          {"Cambiar contraseña"}
        </Button>
      </div>
    </div>
  );

  return (
    <div className={classes.paper}>
      <Header />
      <div style={style.title}>Confimar Correo</div>
      {renderConfirm()}
      <div className={classes.redirets}>
        Ya tu cuenta esta verificada?
        <Button onClick={() => history.push("/login")} color={"primary"}>
          Iniciar Sesión
        </Button>
        <br />
      </div>
    </div>
  );
};

const style = {
  paper: {
    padding: "16px"
  },
  layout: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(50vh  - 90px)"
  },
  title: {
    fontSize: "28px",
    textAlign: "center",
    marginTop: 50
  },
  form: {
    width: "320px",
    display: "flex",
    flexFlow: "column",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  signup: {
    fontSize: "14px",
    marginBottom: "16px"
  },
  signInButton: {
    marginBottom: "16px",
    width: "80%"
  },
  button: {
    margin: "8px 0"
  },
  error: {
    width: "95%",
    margin: "8px",
    fontSize: 12,
    color: "rgb(200,0,0)"
  }
};
const useStyles = makeStyles(theme => ({
  logo: {
    width: 64
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  redirets: {
    marginTop: 30
  }
}));

// Decorate the form component
export default ResendConfirm;
