import React, { Fragment, useState, useEffect } from "react";
// import TextField from "@material-ui/core/TextField";
import { Grid, Box } from "@material-ui/core/";
import Dialog from "@material-ui/core/Dialog";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { getModel, delModel } from "../../services/service.api";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../App.css";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import PecientForm from "./PharmacyForm";
import Button from "@material-ui/core/Button";

export default function ListRecipe() {
  const [handleModal, setHandleModal] = useState({ open: false });
  const [pharmacys, setPharmacys] = useState([]);
  const [showAlert, setShowAlert] = useState({ open: false });

  const getPacientes = async () => {
    try {
      const { data } = await getModel("pharmacys", {});
      if (data) {
        setPharmacys(data);
      } else {
        setPharmacys([]);
      }
    } catch (error) {
      console.log(error);
      setShowAlert({
        open: true,
        message: error.message,
        type: "error"
      });
    }
  };
  const deleteItem = async itemId => {
    try {
      await delModel("pharmacys")(itemId);
      setShowAlert({
        open: true,
        message: "Farmacia borrada Satisfactoriamente!",
        type: "success"
      });
    } catch (error) {
      console.log(error);
      setShowAlert({
        open: true,
        message: error.message,
        type: "error"
      });
    }
  };
  useEffect(
    () => {
      getPacientes();
    },
    [showAlert, handleModal]
  );

  return (
    <Fragment>
      <Box boxShadow={5}>
        <Box p={1} m={4}>
          <Grid>
            <Box display="flex" p={4}>
              <Box p={1} flexGrow={1}>
                <h1>Listado Farmacias</h1>
              </Box>
              {/* <Box mr={2}>
                <TextField id="busqueda" label="Buscar" />
              </Box> */}
              <Box p={1}>
                <Button
                  style={{ borderRadius: 10 }}
                  color="primary"
                  variant="contained"
                  onClick={() => setHandleModal({ open: true })}
                >
                  Crear Farmacia
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid container spacing={4}>
            <Dialog
              onClose={() => setHandleModal({})}
              aria-labelledby="customized-dialog-title"
              open={handleModal.open}
            >
              <PecientForm
                {...{
                  pharmacy: handleModal.pharmacy,
                  setHandleModal,
                  setShowAlert
                }}
              />
            </Dialog>
          </Grid>
          <Grid>
            <Table>
              <Thead>
                <Tr align="right">
                  <Th>Local Id</Th>
                  <Th>Nombre</Th>
                  <Th>Dirección</Th>
                  <Th>Estatus</Th>
                  <Th align="center">OPCIONES</Th>
                </Tr>
              </Thead>
              <Tbody>
                {pharmacys.map((pharmacy, key) => (
                  <Tr align="right" key={key}>
                    <Td>{pharmacy.local_id}</Td>
                    <Td>{pharmacy.local_name}</Td>
                    <Td>{pharmacy.local_address}</Td>
                    <Td>{pharmacy.status}</Td>
                    <Td align="center">
                      <EditIcon
                        color="primary"
                        onClick={() => setHandleModal({ open: true, pharmacy })}
                      />
                      <DeleteIcon
                        color="primary"
                        onClick={() => deleteItem(pharmacy.id)}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Grid>
        </Box>
      </Box>
      <Snackbar
        open={showAlert.open}
        autoHideDuration={6000}
        onClose={() => setShowAlert({})}
      >
        <Alert onClose={() => setShowAlert({})} severity={showAlert.type}>
          {showAlert.message}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}
