import React, { Fragment } from "react";

import Register from "../components/Register/";

const register = () => {
  return (
    <Fragment>
      <Register />
    </Fragment>
  );
};

export default register;
