import React from "react";
import { Route, useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";

const PrivateRoute = props => {
  let { user } = props;
  const location = useLocation();
  return (
    <Route
      render={() => {
        return user && user.username ? (
          <props.component {...{ props }} />
        ) : (
          <Redirect
            to={`/login${
              location.pathname === "/farmaceutico"
                ? "?redirect=" + location.pathname + location.search
                : ""
            }`}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
