import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
const ConfirmDialog = props => {
  const {
    title,
    children,
    open,
    ConfirmMedicine,
    confirmOpen,
    setconfirmOpen,
    row
  } = props;
  return (
    <Dialog
      open={open}
      onClose={() =>
        setconfirmOpen({
          ...confirmOpen,
          [`${row.id}`]: false
        })
      }
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            console.log(row);
            setconfirmOpen({
              ...confirmOpen,
              [`${row.id}`]: false
            });
          }}
          color="default"
        >
          No
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setconfirmOpen({
              ...confirmOpen,
              [`${row.id}`]: false
            });
            ConfirmMedicine(row);
          }}
          color="secondary"
        >
          Si
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
