import React from "react";

import Header from "./";

import { makeStyles } from "@material-ui/core";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  toolbar: {
    paddingRight: 24
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));

function ContainerNavbar(props) {
  const { component: Component } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header />

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Component />
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}

export default ContainerNavbar;
