import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import Button from "@material-ui/core/Button";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Medicina from "./Medicina";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  buttonStyle: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: "auto",
    float: "right"
  }
}));

export default function AccordForm(props) {
  const { valuesRecipe, setValuesRecipe, loading } = props;
  const [allowRegister, setallowRegister] = useState(false);
  const itemVal = [];
  const handleChange = panel => (event, isExpanded) => {
    setValuesRecipe({ ...valuesRecipe, expanded: isExpanded ? panel : false });
  };

  for (let index = 0; index < valuesRecipe.length; index++) {
    itemVal.push(valuesRecipe[index]);
  }

  const classes = useStyles();

  const deleteItem = itemIndex => {
    if (valuesRecipe.medicinesArray.length <= 1) {
      return;
    }
    setValuesRecipe({
      ...valuesRecipe,
      medicinesArray: [
        ...valuesRecipe.medicinesArray.filter((_, i) => i !== itemIndex)
      ]
    });
  };

  return (
    <div className={classes.root}>
      {valuesRecipe.medicinesArray.map((item, index) => (
        <Accordion
          key={index}
          expanded={valuesRecipe.expanded === index}
          onChange={handleChange(index)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid
              justify="space-between" // Add it here :)
              container
              spacing={4}
            >
              <Grid item>
                <Typography className={classes.heading}>
                  Medicina {index + 1}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <Medicina
                {...{
                  valuesRecipe,
                  setValuesRecipe,
                  index,
                  deleteMedicine: () => deleteItem(index)
                }}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      ))}

      {!loading && (
        <Button
          className={classes.buttonStyle}
          disabled={allowRegister}
          variant="outlined"
          color="primary"
          value="Add"
          onClick={() => {
            setValuesRecipe({
              ...valuesRecipe,
              expanded: valuesRecipe.medicinesArray.length,
              medicinesArray: [
                ...valuesRecipe.medicinesArray,
                valuesRecipe.medicinesArray.length
              ]
            });
            if (valuesRecipe.medicinesArray.length === 4) {
              setallowRegister(true);
            }
          }}
        >
          Agregar Medicina
        </Button>
      )}
    </div>
  );
}
