const trans = key => {
  let messages = {
    "Incorrect username or password.": "Correo o contraseña incorrecto",
    "Invalid verification code provided, please try again.":
      "Se proporcionó un código de verificación no válido. Vuelva a intentarlo.",
    "Password does not conform to policy: Password must have symbol characters":
      "La contraseña no cumple con los parametros establecidos, debe contener al menos un caracter especial",
    "User is disabled.": "Usuario Desactivado"
  };

  return messages[key] || "Hubo un error";
};
export const responseError = error => {
  try {
    if (error.message) {
      return trans(error.message);
    }
    if (!error.response) {
      return console.log(error);
    }
    if (error.response && error.response.data.msg) {
      const erro = error.response.data.msg.includes("Not Found")
        ? "No se encontro la ruta"
        : error.response.data.msg;
      return erro;
    }
    if (error.response && error.response.data && error.response.data.message) {
      const erro = error.response.data.message.includes([
        "User account already exists"
      ])
        ? "El usuario ya existe"
        : error.response.data.message;
      return erro;
    }
    if (
      error.response &&
      error.response.data &&
      error.response.data.length &&
      error.response.data[0].original.sqlMessage
    ) {
      return error.response.data.original.sqlMessage;
    }
    if (
      error.response &&
      error.response.data &&
      error.response.data.errors.length
    ) {
      return (
        error.response.data.errors[0].msg ||
        error.response.data.errors[0].message ||
        "Server error"
      );
    }
  } catch (error) {
    console.log(error);
    return "Hubo un error";
  }
};
