import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Auth } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  textField,
  required,
  messages,
  minLength,
  translateMessageAuth
} from "../../utils.auth";
import Header from "../../navbarPublic/Header";
import RenderLoading from "../../commons/loading/loading";

const ResendConfirm = () => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState(undefined);
  const classes = useStyles();
  let location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlEmail = urlParams.get("email");
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      const getData = async () => {
        try {
          if (urlEmail !== undefined && urlEmail !== "") {
            setValues({ ...values, email: urlEmail });
          }
        } catch (error) {
          console.log("error", error);
        }
      };
      getData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [urlEmail, errors]
  );

  const confirmCode = async values => {
    try {
      setLoading(true);
      await Auth.forgotPasswordSubmit(
        values.email.toLowerCase(),
        values.code,
        values.password
      );
      setLoading(false);
      history.push("/login?changePass=true");
    } catch (error) {
      setLoading(false);
      console.log(error);
      setErrors(translateMessageAuth(error.message));
    }
  };

  const handleSubmit = funct => {
    if (required(values.email) === "Required") {
      return setErrors(messages.requireEmail);
    }
    if (required(values.code) === "Required") {
      return setErrors(messages.requireCode);
    }
    if (required(values.password) === "Required") {
      return setErrors(messages.requirePassword);
    }
    if (minLength(values.password) !== true) {
      return setErrors(minLength(values.password));
    }
    funct(values);
  };
  const getMessageUrl = () => {
    if (urlParams.get("email") !== "") {
      return (
        <div style={style.error}>
          Se ha enviado un correo con el código de verificación
        </div>
      );
    }
    return null;
  };
  const renderConfirm = () => (
    <div style={style.layout}>
      <div style={style.form}>
        {getMessageUrl()}
        {textField({
          disabled: loading,
          name: "email",
          label: "Correo",
          action: setValues,
          defaultValue: urlParams.get("email") || "",
          values
        })}
        {textField({
          disabled: loading,
          name: "code",
          label: "Código",
          action: setValues,
          values
        })}
        {textField({
          disabled: loading,
          name: "password",
          label: "Contraseña",
          action: setValues,
          type: "password",
          values
        })}
        <div style={style.error}>
          {errors && errors !== "undefined" ? errors : ""}
        </div>
        {loading ? (
          <RenderLoading />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => handleSubmit(confirmCode)}
          >
            {"Cambiar contraseña"}
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <div className={classes.paper}>
      <Header />
      <div style={style.title}>Confimar Correo</div>
      {renderConfirm()}
      <div className={classes.redirets}>
        Ya tu cuenta esta verificada?
        <Button onClick={() => history.push("/login")} color={"primary"}>
          Iniciar Sesión
        </Button>
        <br />
      </div>
    </div>
  );
};

const style = {
  paper: {
    padding: "16px"
  },
  layout: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(55vh - 90px)"
  },
  title: {
    marginTop: 50,
    fontSize: "28px",
    textAlign: "center"
  },
  form: {
    width: "320px",
    display: "flex",
    flexFlow: "column",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  signup: {
    fontSize: "14px",
    marginBottom: "16px"
  },
  signInButton: {
    marginBottom: "16px",
    width: "80%"
  },
  button: {
    margin: "8px 0"
  },
  error: {
    width: "95%",
    margin: "8px",
    color: "rgb(200,0,0)"
  }
};
const useStyles = makeStyles(theme => ({
  logo: {
    width: 64
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  redirets: {
    marginTop: 30
  }
}));

export default ResendConfirm;
