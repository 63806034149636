import React, { Fragment, useState, useEffect } from "react";
// import TextField from "@material-ui/core/TextField";
import { Grid, Box } from "@material-ui/core/";
import Dialog from "@material-ui/core/Dialog";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import { getModel } from "../../services/service.api";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../App.css";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import PecientForm from "./PacientForm";

export default function ListRecipe(props) {
  const { user } = props;
  const [handleModal, setHandleModal] = useState({ open: false });
  const [pacientes, setPcientes] = useState([]);
  const [showAlert, setShowAlert] = useState({ open: false });

  useEffect(
    () => {
      const getPacientes = async () => {
        const { data } = await getModel(
          `peoples/doctor/${user.user_id}/patients`,
          {
            params: { role_id: 3 }
          }
        );
        if (data) {
          setPcientes(data);
        } else {
          setPcientes([]);
        }
      };
      getPacientes();
    },
    [showAlert, handleModal, user]
  );
  return (
    <Fragment>
      <Box boxShadow={5}>
        <Box p={1} m={4}>
          <Grid>
            <Box display="flex" p={4}>
              <Box p={1} flexGrow={1}>
                <h1>Listado Pacientes</h1>
              </Box>
              {/* <Box mr={2}>
                <TextField id="busqueda" label="Buscar" />
              </Box> */}
              {/* <Box p={1}>
                <Button
                  style={{ borderRadius: 10 }}
                  color="primary"
                  variant="contained"
                  onClick={handleClickOpen}
                >
                  Crear Paciente
                </Button>
              </Box> */}
            </Box>
          </Grid>
          <Grid container spacing={4}>
            <Dialog
              onClose={() => setHandleModal({ open: false })}
              aria-labelledby="customized-dialog-title"
              open={handleModal.open}
            >
              <PecientForm
                {...{
                  person: handleModal.profile,
                  setHandleModal,
                  setShowAlert
                }}
              />
            </Dialog>
          </Grid>
          <Grid>
            <Table>
              <Thead>
                <Tr align="right">
                  <Th>Nombre</Th>
                  <Th>Apellido</Th>
                  <Th>Genero</Th>
                  <Th>Rut</Th>
                  <Th>Correo</Th>
                  <Th align="center">OPCIONES</Th>
                </Tr>
              </Thead>
              <Tbody>
                {pacientes.map(({ profile }, key) => (
                  <Tr align="right" key={key}>
                    <Td>{profile.first_name}</Td>
                    <Td>{profile.last_name}</Td>
                    <Td>{profile.gender}</Td>
                    <Td>{profile.rut}</Td>
                    <Td>{profile.email}</Td>
                    <Td align="center">
                      <IconButton
                        aria-label="Editar"
                        color="primary"
                        style={{ borderRadius: 10 }}
                        variant="contained"
                        onClick={() => setHandleModal({ open: true, profile })}
                      >
                        <EditIcon />
                      </IconButton>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Grid>
        </Box>
      </Box>
      <Snackbar
        open={showAlert.open}
        autoHideDuration={6000}
        onClose={() => setShowAlert({})}
      >
        <Alert onClose={() => setShowAlert({})} severity={showAlert.type}>
          {showAlert.message}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}
