import React, { Fragment } from "react";
import { Grid } from "@material-ui/core/";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import logoTOC from "../../logoTOC.png";
import { Auth } from "aws-amplify";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { userLogOut } from "../../redux/actions/auth";
import { isAutenticate } from "../utils.auth";

const HeaderComponent = props => {
  const { signOut, user } = props;
  const style = {
    AppBar: {
      background: "#140126",
      padding: "10px"
    }
  };
  const myhandleLogout = async () => {
    await Auth.signOut();
    signOut();
  };
  return (
    <Fragment>
      <Grid>
        <AppBar>
          <Toolbar style={style.AppBar}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              style={{ flexGrow: 1 }}
            >
              <img src={logoTOC} alt="logoTOC" />
            </Typography>

            {isAutenticate(user) && (
              <Typography style={{ display: "flex" }}>
                <Button onClick={myhandleLogout} color="secondary">
                  Salir
                </Button>
              </Typography>
            )}
          </Toolbar>
        </AppBar>
      </Grid>
    </Fragment>
  );
};
const mapStatetoProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => {
      dispatch(userLogOut());
    }
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(HeaderComponent);
