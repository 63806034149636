import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from "@material-ui/core";
import ColorizeIcon from "@material-ui/icons/Colorize";
import { useHistory } from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import EnhancedEncryptionIcon from "@material-ui/icons/EnhancedEncryption";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import SettingsIcon from "@material-ui/icons/Settings";
const Menu = props => {
  let history = useHistory();
  const { user } = props.props;

  const [rolemenu, setRoleMenu] = useState(0);
  useEffect(
    () => {
      setRoleMenu(user.role_id);
    },
    [user]
  );
  return (
    <div>
      <List component="nav" aria-label="cicle">
        {rolemenu === 1 ? (
          <>
            <ListItem button onClick={() => history.push("/farmacias")}>
              <ListItemIcon>
                <AddShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary="Farmacias" />
            </ListItem>
            <ListItem button onClick={() => history.push("/managers")}>
              <ListItemIcon>
                <EmojiTransportationIcon />
              </ListItemIcon>
              <ListItemText primary="Managers" />
            </ListItem>
            <ListItem button onClick={() => history.push("/medicos")}>
              <ListItemIcon>
                <LocalHospitalIcon />
              </ListItemIcon>
              <ListItemText primary="Medicos" />
            </ListItem>

            <ListItem button onClick={() => history.push("/adminsfarma")}>
              <ListItemIcon>
                <EnhancedEncryptionIcon />
              </ListItemIcon>
              <ListItemText primary="Farmaceuticos" />
            </ListItem>
            <ListItem button onClick={() => history.push("/admins")}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Administradores" />
            </ListItem>
          </>
        ) : null}
        {rolemenu === 5 ? (
          <ListItem button onClick={() => history.push("/adminfarma")}>
            <ListItemIcon>
              <AssignmentIndIcon />
            </ListItemIcon>
            <ListItemText primary="Admin-Farmacias" />
          </ListItem>
        ) : null}

        {rolemenu === 2 ? (
          <ListItem button onClick={() => history.push("/pacientes")}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Usuarios" />
          </ListItem>
        ) : null}
        {rolemenu === 2 ? (
          <ListItem button onClick={() => history.push("/recetas")}>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Recetas" />
          </ListItem>
        ) : null}
        {rolemenu === 4 || rolemenu === 5 ? (
          <ListItem button onClick={() => history.push("/farmaceutico")}>
            <ListItemIcon>
              <ColorizeIcon />
            </ListItemIcon>
            <ListItemText primary="Farmacéutico" />
          </ListItem>
        ) : null}
      </List>

      <Divider />
    </div>
  );
};

export default Menu;
