import {
  baseUrl,
  urlToc,
  authToc,
  urlRegistroCivil,
  tokenRegistro
} from "../../config/config";
import React, { useState, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useHistory } from "react-router-dom";
import { getModel, createModelJson } from "../../services/service.api";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputMask from "react-input-mask";
import Header from "../navbarPublic/Header";
import RenderLoading from "../commons/loading/loading";
import { Auth } from "aws-amplify";

import {
  TextField,
  Select,
  Container,
  Link,
  Grid,
  Box,
  Slide,
  Dialog
} from "@material-ui/core";
import { responseError } from "../commons/errors/utils";
import { validateRut } from "@fdograph/rut-utilities";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  appBar: {
    position: "relative"
  },

  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RegisterUser = props => {
  const { userRegister, userCognito, signOut } = props;
  const classes = useStyles();
  const [values, setValues] = useState({});
  const [user, setUser] = useState({});
  const doctor = useState(true);
  const [allowRegister, setallowRegister] = useState(false);
  const [tocScan, setTocScan] = useState(false);
  const [imgqr, setImgqr] = useState([]);
  const [linkqr, setLinkqr] = useState([]);
  const [interval, setIntevalDato] = useState(undefined);
  const [errorsSubmit, setErrorsSubmite] = useState(undefined);
  const { register, handleSubmit, errors, setError } = useForm();
  const [loading, setLoading] = useState(false);

  let history = useHistory();

  const assignCognito = async () => {
    setUser({ ...user, cognito_id: userCognito.attributes.sub });
  };
  const ValidateRutCivil = async data => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");

    let raw = JSON.stringify({
      rut: data.rut,
      serie: data.serial_number,
      token: tokenRegistro
    });

    let requestOptions = {
      method: "POST",
      headers: headers,
      body: raw,
      mode: "no-cors"
    };

    try {
      const resp = await fetch(urlRegistroCivil, requestOptions);
      const data = await resp.json();

      if (data.tipo === "success") {
        if (data.IndVigencia === "N") {
          setallowRegister(true);
          setError("rut", {
            type: "manual",
            message: "RUT no vigente"
          });
        }
      } else {
        setError("rut", {
          type: "manual",
          message: data.message
        });
      }
    } catch (error) {
      return { data: [] };
    }
  };

  const getdataToc = async () => {
    let headers = new Headers();
    headers.append("Authorization", authToc);
    headers.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      callbackUrl: baseUrl + "/peoples/toc",
      filters: {
        name: true,
        last_name: true,
        rut: true,
        serial_number: true,
        gender: true,
        age: { over: 18 }
      },
      frontCustomizationId: "q2",
      verificationType: "liveness",
      cID: values.rut
    });

    let requestOptions = {
      method: "POST",
      headers: headers,
      body: raw
    };
    const resp = await fetch(urlToc, requestOptions);
    const data = await resp.json();
    setImgqr(data.data.qr_code);
    setLinkqr(data.data.qr_content);
  };

  const TocScan = async () => {
    const { data } = await getModel("peoples", {
      params: { rut: values.rut }
    });
    return data;
  };

  const handleClickOpen = async () => {
    await getdataToc();
    const int = setInterval(async () => {
      let data = null;
      data = await TocScan();
      if (data && data.length) {
        setValues({
          ...values,
          first_name: data[0].first_name,
          last_name: data[0].last_name,
          gender: data[0].gender,
          people_id: data[0].id,
          serial_number: data[0].serial_number,
          email: userCognito.attributes.email,
          id: data[0].id
        });

        setUser({
          ...user,
          people_id: data[0].id,
          role_id: 2,
          cognito_id: userCognito.attributes.sub
        });
        setTocScan(false);
        ValidateRutCivil(data[0]);
      }
    }, 5000);
    setIntevalDato(int);
  };

  const handleClose = () => {
    setTocScan(false);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      setUser({ ...user, ...values });
      delete user.id;
      const userdb = await createModelJson("peoples/user", {})({
        userInfo: { ...user },
        ...values
      });
      const userFull = {
        ...user,
        ...values,
        user_id: userdb.id,
        role_id: userdb.role_id,
        pharmacy_id: userdb.pharmacy_id
      };
      userRegister(userFull);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrorsSubmite(responseError(error));
    }
  };

  const onScanSubmit = async () => {
    if (values.rut && values.rut.length >= 9) {
      if (validateRut(values.rut) === true) {
        console.log("666666");
        const { data: userdb } = await getModel("peoples", {
          params: { rut: values.rut }
        });
        if (userdb && userdb.length) {
          setValues({
            ...values,
            ...userdb[0],
            email: userCognito.attributes.email,
            people_id: (userdb[0].userInfo || {}).people_id,
            role_id: 2
          });
          setUser({ ...user, people_id: userdb[0].id });
        } else {
          setValues({ email: userCognito.attributes.email, rut: values.rut });
          setTocScan(true);
        }
      } else {
        setError("rut", {
          type: "manual",
          message: "Rut invalido"
        });
      }
    }
  };

  useEffect(
    () => {
      if (userCognito && userCognito.attributes) {
        assignCognito();
      }
      if (userCognito && userCognito.rut) {
        history.push("/farmacia");
      }
      if (tocScan) {
        handleClickOpen();
      } else {
        if (interval !== null) {
          clearInterval(interval);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tocScan, allowRegister, userCognito]
  );

  const login = async () => {
    signOut();
    await Auth.signOut();
    history.push("/login");
  };
  return (
    <Fragment>
      <Grid item xs={12} md={12} lg={12}>
        <Header />
        <Grid>
          <Box p={4}>
            <Container component="main" maxWidth="sm">
              <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                  Registro
                </Typography>
                <Dialog
                  open={tocScan}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle id="alert-dialog-slide-title">
                    {"TOC Identidad Digital"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      <img
                        src={"data:image/png;base64," + imgqr}
                        alt={"unagen toc"}
                      />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <a
                      href={linkqr}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      rel="noopener noreferrer"
                    >
                      <Button color="primary">Escanear</Button>
                    </a>

                    <Button onClick={handleClose} color="primary">
                      Cancelar
                    </Button>
                  </DialogActions>
                </Dialog>
                <form
                  className={classes.form}
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Grid item sm={12}>
                    <InputMask
                      maskChar=""
                      mask="99999999-k"
                      formatChars={{
                        "9": "[0-9]",
                        k: "[0-9,k-k,K-K]"
                      }}
                      onChange={({ target: { value } }) => {
                        if (value.length <= 9) {
                          setError("rut", {});
                        }
                        setValues({ ...values, rut: value });
                      }}
                      onBlur={() => {
                        setError("rut", {
                          type: "manual",
                          message: ""
                        });
                        onScanSubmit();
                      }}
                      inputProps={{
                        minLength: 10,
                        maxLength: 10
                      }}
                    >
                      {() => (
                        <TextField
                          id="rut"
                          label="RUT"
                          name="rut"
                          variant="outlined"
                          margin="dense"
                          type="text"
                          fullWidth
                          autoFocus
                          inputRef={register({
                            required: "RUT Obligatorio.",
                            minLength: {
                              value: 10,
                              message: "Tu rut debe tener 10 caracteres."
                            },
                            maxLength: {
                              value: 10,
                              message: "Tu rut debe tener 10 caracteres."
                            },
                            pattern: {
                              value: /^[0-9]+[-|‐]{1}[0-9kK]{1}/i,
                              message: "Introduzca rut válido"
                            }
                          })}
                        />
                      )}
                    </InputMask>
                    <ErrorMessage
                      className="invalid-feedback"
                      name="rut"
                      as="div"
                      errors={errors}
                    />
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={true}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        id="firstName"
                        label="Nombre"
                        value={values.first_name || ""}
                        name="firstName"
                        onChange={({ target: { value } }) =>
                          setValues({ ...values, first_name: value })
                        }
                        autoComplete="firstName"
                        inputRef={register({
                          required: "Nombre Obligatorio."
                        })}
                      />
                      <ErrorMessage
                        className="invalid-feedback"
                        name="firstName"
                        as="div"
                        errors={errors}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={true}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        id="lastName"
                        label="Apellido"
                        value={values.last_name || ""}
                        name="lastName"
                        onChange={({ target: { value } }) =>
                          setValues({ ...values, last_name: value })
                        }
                        autoComplete="lastName"
                        inputRef={register({
                          required: "Apellido Obligatorio."
                        })}
                      />
                      <ErrorMessage
                        className="invalid-feedback"
                        name="lastName"
                        as="div"
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Select
                        disabled={true}
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        id="gender"
                        name="gender"
                        label="Sexo"
                        value={values.gender || ""}
                        onChange={({ target: { value } }) =>
                          setValues({ ...values, gender: value })
                        }
                      >
                        <MenuItem value="Female">Femenino</MenuItem>
                        <MenuItem value="Male">Masculino</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={true}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        defaultValue={userCognito && userCognito.username}
                      />

                      <ErrorMessage
                        className="invalid-feedback"
                        name="email"
                        as="div"
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputMask
                        disabled={allowRegister}
                        mask="+56 9 99999999"
                        maskChar=" "
                        onChange={({ target: { value } }) =>
                          setValues({ ...values, phone: value })
                        }
                      >
                        {() => (
                          <TextField
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            id="phone"
                            label="Telefono"
                            name="phone"
                            autoComplete="phone"
                            inputRef={register({
                              required: "Números telefónico Obligatorio."
                            })}
                          />
                        )}
                      </InputMask>
                      <ErrorMessage
                        className="invalid-feedback"
                        name="phone"
                        as="div"
                        errors={errors}
                      />
                    </Grid>

                    {doctor ? (
                      <Box component={Grid} item xs={12}>
                        <TextField
                          disabled={allowRegister}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          name="rcm"
                          label="RCM"
                          id="rcm"
                          inputRef={register}
                          value={user.rcm || ""}
                          onChange={({ target: { value } }) => {
                            setValues({ ...values, rcm: value });
                            setUser({ ...user, rcm: value });
                          }}
                          inputProps={{
                            minLength: 6,
                            maxLength: 20
                          }}
                        />
                        <ErrorMessage
                          className="invalid-feedback"
                          name="rcm"
                          as="div"
                          errors={errors}
                        />
                      </Box>
                    ) : null}

                    <Grid item xs={12}>
                      <TextField
                        disabled={allowRegister}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        id="address"
                        label="Dirección"
                        name="address"
                        onChange={({ target: { value } }) =>
                          setValues({ ...values, address: value })
                        }
                        autoComplete="address"
                        inputRef={register({
                          required: "Dirección es Obligatoria.",
                          minLength: {
                            value: 10,
                            message:
                              "Tu dirección debe tener al menos 10 caracteres."
                          }
                        })}
                      />
                      <ErrorMessage
                        className="invalid-feedback"
                        name="address"
                        as="div"
                        errors={errors}
                      />
                    </Grid>
                    <div style={style.error}>
                      {errorsSubmit && errorsSubmit !== "undefined"
                        ? errorsSubmit
                        : ""}
                    </div>
                    {loading ? (
                      <RenderLoading />
                    ) : (
                      <Button
                        disabled={allowRegister}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        Registrarse
                      </Button>
                    )}
                  </Grid>
                </form>
                <Link
                  variant="body2"
                  onClick={login}
                  style={{ cursor: "pointer" }}
                >
                  Tienes cuenta, ¿deseas iniciar sesión?
                </Link>
              </div>
            </Container>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};
const style = {
  error: {
    width: "95%",
    margin: "8px",
    fontSize: "13px",
    color: "rgb(200,0,0)"
  }
};

export default RegisterUser;
