import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core/";
import { editModel } from "../../services/service.api";
import { Button } from "@material-ui/core";
import {
  maxLength,
  email,
  required,
  textField,
  messages,
  translateMessageAuth,
  requireMessage,
  rutIsValid
} from "../utils.auth";
import RenderLoading from "../commons/loading/loading";

const PacienteForm = props => {
  const { person, setHandleModal, setShowAlert } = props;
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      if (person && person.id) {
        setValues(person);
      }
    },
    [person]
  );

  const submit = async () => {
    try {
      setLoading(true);
      const res = await editModel("peoples", {})(person.id, values);
      setLoading(false);
      if (res.id) {
        setHandleModal({ open: false });
        setShowAlert({
          open: true,
          message: "Paciente editado Satisfactoriamente!",
          type: "success"
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      setErrors(translateMessageAuth(error.message));
    }
  };

  const handleSubmit = funct => {
    if (required(values.email) === "Required") {
      return setErrors(messages.requireEmail);
    }
    if (maxLength(values.email)) {
      return setErrors("Excede el número maximo de caracteres");
    }
    if (email(values.email) !== true) {
      return setErrors(email(values.email));
    }
    if (required(values.first_name) === "Required") {
      return setErrors(requireMessage("Nombre"));
    }
    if (required(values.last_name) === "Required") {
      return setErrors(requireMessage("Apellido"));
    }
    if (required(values.rut) === "Required") {
      return setErrors(requireMessage("Rut"));
    }
    if (!rutIsValid(values.rut)) {
      return setErrors("El rut no es valido");
    }

    setErrors(undefined);
    funct(values);
  };

  const renderForm = () => (
    <Box boxShadow={5}>
      <Box m={4}>
        <Box textAlign="center">
          <h3>Editar Paciente</h3>
        </Box>
        <Grid container spacing={2}>
          <Grid item md={6}>
            {textField({
              disabled: loading,
              name: "first_name",
              label: "Nombre",
              action: setValues,
              values,
              size: "small",
              defaultValue: (person || {}).first_name
            })}
          </Grid>
          <Grid item md={6}>
            {textField({
              disabled: loading,
              name: "last_name",
              label: "Apellido",
              action: setValues,
              values,
              size: "small",
              defaultValue: (person || {}).last_name
            })}
          </Grid>
          <Grid item md={6}>
            {textField({
              disabled: loading,
              name: "email",
              label: "Correo",
              action: setValues,
              values,
              size: "small",
              defaultValue: (person || {}).email
            })}
          </Grid>
          <Grid item md={6}>
            {textField({
              disabled: loading,
              name: "rut",
              label: "Rut",
              action: setValues,
              values,
              size: "small",
              defaultValue: (person || {}).rut
            })}
          </Grid>
        </Grid>
        <div style={style.error}>
          {errors && errors !== "undefined" ? errors : ""}
        </div>
        {loading ? (
          <RenderLoading />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => handleSubmit(submit)}
          >
            {"Editar"}
          </Button>
        )}
      </Box>
    </Box>
  );

  return renderForm();
};

const style = {
  error: {
    width: "95%",
    margin: "8px",
    fontSize: "13px",
    color: "rgb(200,0,0)"
  }
};

export default PacienteForm;
