import { connect } from "react-redux";

import SignInComponent from "./SignIn";
import { userSignIn, userLogOut } from "../../../redux/actions/auth";

const mapStatetoProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    userLoggedIn: user => {
      dispatch(userSignIn(user));
    },
    userLogOut: () => {
      dispatch(userLogOut());
    }
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(SignInComponent);
