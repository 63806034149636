import { connect } from "react-redux";

import ListPacientes from "./ListPacientes";

const mapStatetoProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(ListPacientes);
