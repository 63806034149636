import React from "react";
import Recipe from "../components/Recipe/";

const recipe = () => {
  return (
    <div>
      <Recipe />
    </div>
  );
};

export default recipe;
