import React from "react";
import { connect } from "react-redux";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import Protected from "./components/Protected";
import PrivateRoute from "./components/routers/PrivateRoute";
import AdminRoute from "./components/routers/AdminRoute/";
import {
  Farmaceuta,
  Login,
  Pharmacy,
  Pacientes,
  Register,
  Recipe,
  SignUp,
  Confirm,
  ForgotPassword,
  ChangePassword,
  ForceChangePassword,
  Home,
  AdminFarmaceuta
} from "./pages/";

import {
  AdminManagers,
  AdminMedicos,
  AdminAdmins,
  AdminFarmaceuticos
} from "./pages/admin/index";

function App(props) {
  const user = props;
  return (
    <Router>
      <div className="app">
        <div className="screen">
          <Switch>
            {/* ROUTER DE ADMIN */}
            <AdminRoute path="/farmacias" component={Pharmacy} />
            <AdminRoute path="/managers" component={AdminManagers} />
            <AdminRoute path="/medicos" component={AdminMedicos} />
            <AdminRoute path="/admins" component={AdminAdmins} />
            <AdminRoute path="/adminsfarma" component={AdminFarmaceuticos} />

            <PrivateRoute path="/protected" component={Protected} />
            <PrivateRoute path="/pacientes" component={Pacientes} />
            <PrivateRoute path="/register" component={Register} />
            <PrivateRoute path="/farmaceutico" component={Farmaceuta} />
            <PrivateRoute path="/home" component={Home} />

            <PrivateRoute path="/adminfarma" component={AdminFarmaceuta} />
            {/* <Route path="/registertoc" component={RegisterToc} /> */}

            <PrivateRoute path="/recetas" component={Recipe} />

            {/* AUTENTICACIÖN CON AWS COGNITO */}
            <Route path="/login" component={Login} />
            <Route path="/signup" component={SignUp} />
            <Route path="/confirm" component={Confirm} />
            <Route path="/forgotPassword" component={ForgotPassword} />
            <Route path="/changePassword" component={ChangePassword} />
            <Route
              path="/forceChangePassword"
              component={ForceChangePassword}
            />

            {/* <Route path="/signup" component={SignUp} />
                <Route path="/resetpassword" component={ResetPassword} />
                <Route path="/changepassword" component={ChangePassword} />
                <Route
                  path="/forcechangepassword"
                  component={ForceChangePassword}
                />
                <Route path="/landing" component={Landing} /> */}

            <Route
              path="/"
              render={() =>
                user && user.username ? (
                  <Redirect to="/protected" />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
          </Switch>
        </div>
      </div>
    </Router>
  );
}

const mapStatetoProps = state => {
  return {
    user: state.user
  };
};
export default connect(mapStatetoProps)(App);
