import React, { Fragment, useState } from "react";
import { Grid, Box } from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import { createModelJson } from "../../services/service.api";
import { required, requireMessage } from "../utils.auth";
import Person from "./Person";
import "../../App.css";
import AccordForm from "./AccordForm";
import RenderLoading from "../commons/loading/loading";

export default function AddRecipe(props) {
  const { handleClose, setShowAlert, user } = props;
  const [registrado, setRegistrado] = React.useState(false);
  const [valuesRecipe, setValuesRecipe] = useState({
    accrdArray: [0],
    expanded: 0,
    medicinesArray: [{}]
  });
  const [valuesUser, setValuesUser] = useState({});
  const [errors, setErrors] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const style = {
    error: {
      width: "95%",
      margin: "8px",
      fontSize: "13px",
      color: "rgb(200,0,0)"
    }
  };

  const handleSubmit = funct => {
    let erro = false;
    if (!valuesRecipe.medicinesArray.length) {
      return setErrors("Campos de medicina son requeridos");
    }
    valuesRecipe.medicinesArray.forEach(item => {
      if (erro) {
        return;
      }
      erro = true;
      if (required(item[`medicine`]) === "Required") {
        erro = true;
        return setErrors(requireMessage("Medicina"));
      }
      if (required(item[`dose_value`]) === "Required") {
        erro = true;
        return setErrors(requireMessage("Dosis"));
      }
      if (required(item[`frequency_value`]) === "Required") {
        erro = true;
        return setErrors(requireMessage("Frecuencia Valor"));
      }
      if (required(item[`frequency_id`]) === "Required") {
        erro = true;
        return setErrors(requireMessage("Frecuencia"));
      }
      if (required(item[`observation`]) === "Required") {
        erro = true;
        return setErrors(requireMessage("Observacion"));
      }
      if (required(item[`quantity`]) === "Required") {
        erro = true;
        return setErrors(requireMessage("Cantidad"));
      }
      if (required(item[`duration_frequency`]) === "Required") {
        erro = true;
        return setErrors(requireMessage("Duración"));
      }
      if (required(item[`duration`]) === "Required") {
        erro = true;
        return setErrors(requireMessage("Duración"));
      }
      erro = false;
      setErrors("");
    });
    if (erro) {
      return;
    }
    funct(valuesRecipe);
  };

  const handleSaveRecipe = async () => {
    try {
      setLoading(true);
      const data = await createModelJson("recipes", {})({
        ...valuesUser,
        ...valuesRecipe,
        doctor_id: user.user_id
      });

      if (data.id) {
        handleClose();
        setShowAlert({
          open: true,
          message: "Receta creado Satisfactoriamente!",
          type: "success"
        });
        setTimeout(() => setShowAlert({}), 3000);
        setLoading(false);
      } else {
        setLoading(false);
        return setErrors("No se pudo Generar el receta");
      }
    } catch (error) {
      setLoading(false);
      return setErrors("No se pudo Generar el Receta");
    }
  };
  const Recipe = () => {
    return (
      <>
        <Box boxShadow={5}>
          <Box m={4}>
            <Box textAlign="center">
              <h1>Receta</h1>
            </Box>
            <Box textAlign="left">
              <p>
                Paciente:{" "}
                <strong>
                  {valuesUser.first_name} {valuesUser.last_name}
                </strong>
              </p>
              <p>
                Rut: <strong>{valuesUser.rut}</strong>
              </p>
            </Box>

            <AccordForm {...{ valuesRecipe, setValuesRecipe, loading }} />

            <Grid item xs={12} md={12}>
              <div style={style.error}>
                {errors && errors !== "undefined" ? errors : ""}
              </div>
            </Grid>

            <Grid item xs={12} md={12}>
              {loading ? (
                <RenderLoading />
              ) : (
                <Button
                  type="submit"
                  style={{ borderRadius: 10 }}
                  color="primary"
                  variant="contained"
                  onClick={() => handleSubmit(handleSaveRecipe)}
                  fullWidth
                >
                  Guardar
                </Button>
              )}
            </Grid>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Fragment>
      {registrado ? (
        Recipe()
      ) : (
        <Person
          {...{
            ...props,
            setRegistrado,
            registrado,
            valuesUser,
            setValuesUser
          }}
        />
      )}
    </Fragment>
  );
}
