import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core/";
import {
  getModel,
  editModel,
  createModelJson
} from "../../../services/service.api";
import { Button, Select, MenuItem, TextField } from "@material-ui/core";
import InputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  maxLength,
  minLength,
  required,
  requireMessage
} from "../../utils.auth";
import { validateRut } from "@fdograph/rut-utilities";
import SelectItems from "../../commons/Select/SelectItems";
import { responseError } from "../../commons/errors/utils";
import RenderLoading from "../../commons/loading/loading";

const style = {
  error: {
    width: "95%",
    margin: "8px",
    fontSize: "13px",
    color: "rgb(200,0,0)"
  }
};
const UserForm = props => {
  const { manager, setHandleModal, setShowAlert, user, ROLE_ID, LABEL } = props;
  const [values, setValues] = useState({});
  const [pharmacys, setPharmacys] = useState([]);
  const [errorsV, setErrorsV] = useState(undefined);
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    const { data } = await getModel("pharmacys", {
      params: { status: "active" }
    });
    if (data) {
      setPharmacys([
        ...data.map(({ local_name, id }) => ({ value: id, label: local_name }))
      ]);
    }
  };
  useEffect(
    () => {
      if (manager && manager.id) {
        setValues({ ...manager.profile, pharmacy_id: manager.pharmacy_id });
      }
      getData();
    },
    [user, manager]
  );

  const onSubmit = async () => {
    try {
      setLoading(true);
      let res = null;
      if (manager) {
        res = await editModel("peoples/", {})(manager.profile.id, values);
      } else {
        res = await createModelJson("peoples/cognito", {})({
          ...values,
          role_id: ROLE_ID
        });
      }

      if (res.id || res.status === 200) {
        setHandleModal({});
        setShowAlert({
          open: true,
          message: `${LABEL} editado Satisfactoriamente!`,
          type: "success"
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setErrorsV(responseError(error));
      setLoading(false);
    }
  };
  const handleInputChangeRut = async rut => {
    if (validateRut(rut) === true) {
      try {
        const { data } = await getModel("peoples", {
          params: { rut }
        });
        if (data && data.length > 0) {
          setValues({
            ...values,
            ...data[0],
            role_id: ROLE_ID,
            people_id: data[0].id
          });
        } else {
          setValues({ rut });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setValues({ rut });
      if (rut.length === 10) {
        setErrorsV(`Rut Invalido`);
      } else {
        setErrorsV();
      }
    }
  };
  const handleSubmitValidate = funct => {
    if (!validateRut(values.rut)) {
      return setErrorsV(`Campo rut es invalid`);
    }

    if (required(values.first_name) === "Required") {
      return setErrorsV(requireMessage("Nombre"));
    }
    if (required(values.last_name) === "Required") {
      return setErrorsV(requireMessage("Apellido"));
    }
    if (required(values.gender) === "Required") {
      return setErrorsV(requireMessage("Sexo"));
    }
    if (required(values.email) === "Required") {
      return setErrorsV(requireMessage("Email"));
    }
    if (required(values.phone) === "Required") {
      return setErrorsV(requireMessage("Telefono"));
    }
    if (required(values.address) === "Required") {
      return setErrorsV(requireMessage("Dirección"));
    }
    if (
      ROLE_ID !== 2 &&
      ROLE_ID !== 1 &&
      required(values.pharmacy_id) === "Required"
    ) {
      return setErrorsV(requireMessage("Farmacia"));
    }
    if ((values.phone || "").includes("_")) {
      return setErrorsV(`Campo Telefono es muy corto`);
    }

    let err = false;
    [
      { name: "first_name", min: 3, max: 150, label: "Nombre" },
      { name: "last_name", min: 3, max: 150, label: "Apellido" },
      { name: "email", min: 3, max: 150, label: "Email" },
      { name: "phone", min: 14, max: 14, label: "Telefono" }
    ].forEach(({ name, min, max, label }) => {
      if (!err) {
        if (maxLength(values[name], max)) {
          err = true;
          return setErrorsV(`Campo ${label} excede cantidad de caracteres`);
        }
        if (minLength(values[name].trim(), min) !== true) {
          err = true;
          return setErrorsV(`Campo ${label} es muy corto`);
        }
        err = false;
      }
    });
    if (err) {
      return;
    }

    setErrorsV(undefined);
    funct(values);
  };

  const renderForm = () => (
    <Box boxShadow={5}>
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit(handleSubmitValidate(onSubmit));
        }}
      >
        <Box m={4}>
          <Box textAlign="center">
            <h3>
              {manager ? "Editar" : "Crear"} {LABEL}
            </h3>
          </Box>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <InputMask
                maskChar=""
                mask="99999999-k"
                formatChars={{
                  "9": "[0-9]",
                  k: "[0-9,k-k,K-K]"
                }}
                onChange={({ target: { value } }) => {
                  handleInputChangeRut(value);
                  setValues({ ...values, rut: value });
                }}
                inputProps={{
                  minLength: 10,
                  maxLength: 10
                }}
                value={(values || {}).rut}
              >
                {() => (
                  <TextField
                    id="rut"
                    label="RUT"
                    name="rut"
                    variant="outlined"
                    margin="dense"
                    type="text"
                    fullWidth
                    autoFocus
                    defaultValue={((values || {}).rut || {}).rut}
                    inputRef={register({
                      required: "RUT Obligatorio.",
                      minLength: {
                        value: 10,
                        message: "Tu rut debe tener 10 caracteres."
                      },
                      maxLength: {
                        value: 10,
                        message: "Tu rut debe tener 10 caracteres."
                      },
                      pattern: {
                        value: /^[0-9]+[-|‐]{1}[0-9kK]{1}/i,
                        message: "Introduzca rut válido"
                      }
                    })}
                  />
                )}
              </InputMask>
              <ErrorMessage
                className="invalid-feedback"
                name="rut"
                as="div"
                errors={errors}
              />
            </Grid>
            <Grid item md={6}>
              <Select
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="gender"
                name="gender"
                label="Sexo"
                value={(values || {}).gender || 0}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, gender: value })
                }
              >
                <MenuItem value={0}>Genero</MenuItem>
                <MenuItem value="Female">Femenino</MenuItem>
                <MenuItem value="Male">Masculino</MenuItem>
              </Select>
            </Grid>
            <Grid item md={6}>
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                id="first_name"
                label="Nombre"
                value={(values || {}).first_name || ""}
                name="first_name"
                onChange={({ target: { value } }) =>
                  setValues({ ...values, first_name: value })
                }
                autoComplete="firstName"
                inputRef={register({
                  required: "Nombre Obligatorio."
                })}
              />
              <ErrorMessage
                className="invalid-feedback"
                name="firstName"
                as="div"
                errors={errors}
              />
            </Grid>

            <Grid item md={6}>
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                id="last_name"
                label="Apellido"
                value={(values || {}).last_name || ""}
                name="last_name"
                onChange={({ target: { value } }) =>
                  setValues({ ...values, last_name: value })
                }
                autoComplete="lastName"
                inputRef={register({
                  required: "Apellido Obligatorio."
                })}
              />
              <ErrorMessage
                className="invalid-feedback"
                name="lastName"
                as="div"
                errors={errors}
              />
            </Grid>
            {ROLE_ID === 2 && (
              <Grid item md={6}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  id="rcm"
                  label="RCM"
                  value={(values || {}).rcm || ""}
                  name="rcm"
                  onChange={({ target: { value } }) =>
                    setValues({ ...values, rcm: value })
                  }
                  autoComplete="firstName"
                  inputRef={register({
                    required: "RCM."
                  })}
                />
                <ErrorMessage
                  className="invalid-feedback"
                  name="firstName"
                  as="div"
                  errors={errors}
                />
              </Grid>
            )}

            <Grid item md={6}>
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                id="email"
                label="Correo"
                name="email"
                value={(values || {}).email || ""}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, email: value })
                }
                inputRef={register({
                  required: "Email Obligatorio."
                })}
              />

              <ErrorMessage
                className="invalid-feedback"
                name="email"
                as="div"
                errors={errors}
              />
            </Grid>

            <Grid item md={6}>
              <InputMask
                mask="+56 9 99999999"
                maskChar=" "
                onChange={({ target: { value } }) =>
                  setValues({ ...values, phone: value })
                }
                value={(values || {}).phone || ""}
              >
                {() => (
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="phone"
                    label="Telefono"
                    name="phone"
                    autoComplete="phone"
                    inputRef={register({
                      required: "Números telefónico Obligatorio."
                    })}
                  />
                )}
              </InputMask>
              <ErrorMessage
                className="invalid-feedback"
                name="phone"
                as="div"
                errors={errors}
              />
            </Grid>

            <Grid item md={6}>
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                id="address"
                label="Dirección"
                name="address"
                onChange={({ target: { value } }) =>
                  setValues({ ...values, address: value })
                }
                autoComplete="address"
                inputRef={register({
                  required: "Dirección es Obligatoria.",
                  minLength: {
                    value: 10,
                    message: "Tu dirección debe tener al menos 10 caracteres."
                  }
                })}
                value={(values || {}).address || ""}
              />
              <ErrorMessage
                className="invalid-feedback"
                name="address"
                as="div"
                errors={errors}
              />
            </Grid>
            {ROLE_ID !== 2 &&
              ROLE_ID !== 1 && (
                <Grid item md={6}>
                  {SelectItems({
                    label: "Seleccionar Farmacia",
                    name: "pharmacy_id",
                    values,
                    value: !values["pharmacy_id"] ? 0 : values["pharmacy_id"],
                    action: setValues,
                    itemsMenu: pharmacys,
                    isObject: true,
                    styles: { marginTop: 8 }
                  })}
                </Grid>
              )}
          </Grid>

          <div style={style.error}>{errorsV && errorsV}</div>
          {loading ? (
            <RenderLoading />
          ) : (
            <Button type="submit" fullWidth variant="contained" color="primary">
              {!manager ? "Crear" : "Editar"}
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );

  return renderForm();
};

export default UserForm;
