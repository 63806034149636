import React from "react";
import { Route /*, useLocation*/ } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { isAdmin } from "../../utils.auth";

const PrivateRoute = props => {
  let { user } = props;
  return (
    <Route
      render={() => {
        return isAdmin(user) ? (
          <props.component {...{ props }} />
        ) : (
          <Redirect to={`/login`} />
        );
      }}
    />
  );
};

export default PrivateRoute;
