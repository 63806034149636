import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core/";
import { editModel, createModelJson } from "../../services/service.api";
import { Button, MenuItem, Select } from "@material-ui/core";
import {
  maxLength,
  required,
  textField,
  translateMessageAuth,
  requireMessage
} from "../utils.auth";

const PacienteForm = props => {
  const { pharmacy, setHandleModal, setShowAlert } = props;
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState(undefined);
  useEffect(
    () => {
      if (pharmacy && pharmacy.id) {
        setValues(pharmacy);
      }
    },
    [pharmacy]
  );

  const submit = async () => {
    try {
      let res = null;
      if (pharmacy) {
        res = await editModel("pharmacys", {})(pharmacy.id, values);
      } else {
        res = await createModelJson("pharmacys", {})(values);
      }
      if (res.id || res.status === 200) {
        setHandleModal({});
        setShowAlert({
          open: true,
          message: "Farmacia editado Satisfactoriamente!",
          type: "success"
        });
      }
    } catch (error) {
      console.log(error);
      setErrors(translateMessageAuth(error.message));
    }
  };

  const handleSubmit = funct => {
    if (required(values.local_id) === "Required") {
      return setErrors(requireMessage("id"));
    }
    if (required(values.local_name) === "Required") {
      return setErrors(requireMessage("nombre"));
    }
    if (required(values.local_address) === "Required") {
      return setErrors(requireMessage("dirección"));
    }
    if (maxLength(values.local_id)) {
      return setErrors("Campo dirección id cantidad de caracteres");
    }
    if (maxLength(values.local_name)) {
      return setErrors("Campo dirección npmbre cantidad de caracteres");
    }
    if (maxLength(values.loca_address)) {
      return setErrors("Campo dirección excede cantidad de caracteres");
    }

    setErrors(undefined);
    funct(values);
  };

  const renderForm = () => (
    <Box boxShadow={5}>
      <Box m={4}>
        <Box textAlign="center">
          <h3>{pharmacy ? "Editar" : "Crear"} Farmacia</h3>
        </Box>
        <Grid container spacing={2}>
          <Grid item md={6}>
            {textField({
              name: "local_id",
              label: "Nro",
              action: setValues,
              values,
              size: "small",
              defaultValue: (pharmacy || {}).local_id
            })}
          </Grid>
          <Grid item md={6}>
            {textField({
              name: "local_name",
              label: "Nombre del loca",
              action: setValues,
              values,
              size: "small",
              defaultValue: (pharmacy || {}).local_name
            })}
          </Grid>
          <Grid item md={6}>
            {textField({
              name: "local_address",
              label: "Dirección",
              action: setValues,
              values,
              size: "small",
              defaultValue: (pharmacy || {}).local_address
            })}
          </Grid>
          <Grid item xs={12} md={6} container>
            <Select
              variant="outlined"
              margin="dense"
              required
              fullWidth
              id="status"
              name="status"
              label="Estado"
              value={(values || {}).status || 0}
              onChange={({ target: { value } }) =>
                setValues({ ...values, status: value })
              }
            >
              <MenuItem value={0}>Estado</MenuItem>
              <MenuItem value="active">Activa</MenuItem>
              <MenuItem value="pending">Pendiente</MenuItem>
              <MenuItem value="restricted">Restringida</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <div style={style.error}>
          {errors && errors !== "undefined" ? errors : ""}
        </div>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => handleSubmit(submit)}
        >
          {!pharmacy ? "Crear" : "Editar"}
        </Button>
      </Box>
    </Box>
  );

  return renderForm();
};

const style = {
  error: {
    width: "95%",
    margin: "8px",
    fontSize: "13px",
    color: "rgb(200,0,0)"
  }
};

export default PacienteForm;
