export const baseUrl =
  process.env.REACT_APP_API_URL || "http://localhost:8686/api";
export const defaultUser = {};
export const optionsFecth = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
};
export const urlToc = "https:///id-backend-sandbox.7oc.cl/client/generate_qr";
export const authToc =
  "Bearer eyJpdiI6ImlZb1JDUzh4SGZwb1lERytvbGJ3Wnc9PSIsInZhbHVlIjoiN1Y5YXViR3FCUEpQV3RnQmNzU240MkZYZ1wvZERXVG5ZbTYzUUFXNnJvdlk9IiwibWFjIjoiY2ZlZDM0NWZkMTAxNDlmMjZiOGE4MTM2YWFlNWFjMDdlODMzM2E2MzQ2ZWQyZmZjMzg5MzUxZGZkOGM5NDVkZiJ9";
export const urlRegistroCivil =
  process.env.REACT_APP_API_URL_REGISTRO || "http://127.0.0.1:5000";

export const tokenRegistro = process.env.REACT_APP_API_TOKEN_REGISTRO || "dev";
