import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Auth } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Header from "../../navbarPublic/Header";
import { email, required, textField, messages } from "../../utils.auth";
import RenderLoading from "../../commons/loading/loading";

const style = {
  paper: {
    padding: "16px"
  },
  layout: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(50vh - 90px)"
  },
  title: {
    fontSize: "28px",
    textAlign: "center",
    marginTop: 50
  },
  form: {
    width: "320px",
    display: "flex",
    flexFlow: "column",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  signup: {
    fontSize: "14px",
    marginBottom: "16px"
  },
  signInButton: {
    marginBottom: "16px",
    width: "80%"
  },
  button: {
    margin: "8px 0"
  },
  error: {
    width: "95%",
    margin: "8px",
    color: "rgb(200,0,0)"
  }
};

const Confirm = props => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState(undefined);
  const [resend, setResend] = useState(0);
  const classes = useStyles();
  let location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const urlEmail = urlParams.get("email");
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      const getData = async () => {
        try {
          if (urlEmail !== undefined && urlEmail !== "") {
            setValues({ email: urlEmail.replace(" ", "+") });
          }
        } catch (error) {
          console.log("error", error);
        }
      };
      getData();
    },
    [props, urlEmail, errors]
  );

  const confirmCode = async () => {
    try {
      setLoading(true);
      setErrors("");
      const res = await Auth.confirmSignUp(values.email, values.code);
      setLoading(false);
      if (res === "SUCCESS") {
        history.push("/login?confirm=true");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      setErrors(error.message);
    }
  };
  const resendConfirmCode = async () => {
    try {
      setErrors("");
      await Auth.resendSignUp(values.email);
      setResend(resend + 1);
    } catch (error) {
      console.log(error);
      setErrors(error.message);
    }
  };

  const handleSubmit = funct => {
    if (required(values.email) === "Required") {
      return setErrors(messages.requireEmail);
    }
    if (email(values.email) !== true) {
      return setErrors(email(values.email));
    }
    if (required(values.code) === "Required") {
      return setErrors(messages.requireCode);
    }
    funct(values);
  };

  const renderConfirm = () => (
    <div style={style.layout}>
      <div style={style.form}>
        {urlEmail &&
          urlEmail !== "" && (
            <div style={style.error}>
              Ha sido enviado {resend.length && resend + "vez"} un codigo de
              verificación a su correo {urlEmail}
            </div>
          )}
        {textField({
          disabled: loading,
          name: "code",
          label: "Código",
          type: "text",
          action: setValues,
          values,
          subAction: e => {
            if (e.key === "Enter") {
              handleSubmit(confirmCode);
            }
          }
        })}
        {textField({
          disabled: loading,
          name: "email",
          label: "Correo",
          action: setValues,
          values,
          value: values.email || ""
        })}
        <div style={style.error}>
          {errors && errors !== "undefined" ? errors : ""}
        </div>
        {loading ? (
          <RenderLoading />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => handleSubmit(confirmCode)}
          >
            {"Verificar Correo"}
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <div className={classes.paper}>
      <Header />
      <div style={style.title}>Confimar Correo</div>
      {renderConfirm()}
      <div className={classes.redirets}>
        Reenviar el
        <Button onClick={() => resendConfirmCode()} color={"primary"}>
          Código
        </Button>
      </div>
      <div className={classes.redirets}>
        Ya tu cuenta esta verificada?
        <Button onClick={() => history.push("/login")} color={"primary"}>
          Iniciar Sesión
        </Button>
      </div>
    </div>
  );
};
const useStyles = makeStyles(theme => ({
  logo: {
    width: 64
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  redirets: {
    marginTop: 30
  }
}));

export default Confirm;
