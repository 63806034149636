import React, { Fragment, useState, useEffect } from "react";
// import TextField from "@material-ui/core/TextField";
import { makeStyles, Grid, Box } from "@material-ui/core/";
import Dialog from "@material-ui/core/Dialog";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { getModel, editModel, delModel } from "../../../services/service.api";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../../App.css";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import FarmaceutaForm from "./UserForm";
import Button from "@material-ui/core/Button";
import DomainDisabledIcon from "@material-ui/icons/DomainDisabled";
import DomainVerificationIcon from "@material-ui/icons/VerifiedUser";
import ConfirmDialog from "../../commons/ConfirmDialog/ConfirmDialog";
import Tooltip from "@material-ui/core/Tooltip";
import RenderLoading from "../../commons/loading/loading";

export default function ListUsers(props) {
  const { user, ROLE_ID, LABEL } = props;
  const classes = useStyles();
  const [handleModal, setHandleModal] = useState({ open: false });
  const [pharmacys, setPharmacys] = useState([]);
  const [showAlert, setShowAlert] = useState({ open: false });
  const [confirmOpen, setconfirmOpen] = useState({});
  const [loading, setLoading] = useState(false);

  const getFarmaceutas = async () => {
    try {
      const { data } = await getModel("users/people", {
        params: { role_id: ROLE_ID }
      });
      if (data) {
        setPharmacys(data);
      } else {
        setPharmacys([]);
      }
    } catch (error) {
      console.log(error);
      setShowAlert({
        open: true,
        message: error.message,
        type: "error"
      });
    }
  };
  const disabledItem = async itemId => {
    try {
      setLoading(true);
      await editModel("users/cogni", {})(itemId.id, {
        status: !itemId.status
      });

      setShowAlert({
        open: true,
        message:
          LABEL +
          " " +
          (!itemId.status ? "Activado" : "Desactivado") +
          " " +
          " Satisfactoriamente!",
        type: "success"
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      setShowAlert({
        open: true,
        message: error.message,
        type: "error"
      });
    }
  };
  const ConfirmDelete = async row => {
    try {
      setLoading(true);
      const { id } = row;
      let data = null;
      const getRecipes = async () => {
        const { data } = await getModel("recipes", {
          params: { doctor_id: id }
        });
        return data;
      };
      const getDataDelivery = async () => {
        const { data } = await getModel("medicinestatusdelivery", {
          params: { pharmacist_id: id }
        });
        return data;
      };
      switch (ROLE_ID) {
        case 2:
          data = await getRecipes();
          break;
        case 4:
        case 5:
          data = await getDataDelivery();
          break;
        default:
          break;
      }

      if (data && data.length) {
        setShowAlert({
          open: true,
          message:
            LABEL +
            " no puede ser borrado!. Posee registros asociado en entregas de Medicamentos",
          type: "warning"
        });
      } else {
        await delModel("users", {})(id, { pharmacy_id: null });
        setShowAlert({
          open: true,
          message: LABEL + " borrado Satisfactoriamente!",
          type: "success"
        });
      }
      setLoading(false);
      setconfirmOpen({
        ...confirmOpen,
        [`${id}`]: false
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setShowAlert({
        open: true,
        message: error.message,
        type: "error"
      });
    }
  };

  useEffect(
    () => {
      getFarmaceutas();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showAlert, handleModal]
  );

  return (
    <Fragment>
      <Box boxShadow={5}>
        <Box p={1} m={4}>
          <Grid>
            <Box display="flex" p={4}>
              <Box p={1} flexGrow={1}>
                <h1>{LABEL}</h1>
              </Box>
              {/* <Box mr={2}>
                <TextField id="busqueda" label="Buscar" />
              </Box> */}
              <Box p={1}>
                <Button
                  style={{ borderRadius: 10 }}
                  color="primary"
                  variant="contained"
                  onClick={() => setHandleModal({ open: true })}
                >
                  Crear {LABEL}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid container spacing={4}>
            <Dialog
              onClose={() => setHandleModal({})}
              aria-labelledby="customized-dialog-title"
              open={handleModal.open}
              ROLE_ID={ROLE_ID}
              LABEL={LABEL}
            >
              <FarmaceutaForm
                {...{
                  manager: handleModal.pharmacy,
                  setHandleModal,
                  setShowAlert,
                  user,
                  ROLE_ID,
                  LABEL
                }}
              />
            </Dialog>
          </Grid>
          <Grid>
            <Table>
              <Thead>
                <Tr align="right">
                  <Th>Rut</Th>
                  <Th>Nombre</Th>
                  <Th>Sexo</Th>
                  <Th>Telefono</Th>
                  {ROLE_ID === 2 && <Th>RCM</Th>}
                  {(ROLE_ID === 4 || ROLE_ID === 5) && <Th>Farmacia</Th>}
                  <Th>Estado</Th>
                  <Th align="center">OPCIONES</Th>
                </Tr>
              </Thead>
              <Tbody>
                {pharmacys.map((pharmacy, key) => (
                  <Tr align="right" key={key}>
                    <Td>{pharmacy.profile.rut}</Td>
                    <Td>
                      {pharmacy.profile.first_name} {pharmacy.profile.last_name}
                    </Td>
                    <Td>{pharmacy.profile.gender}</Td>
                    <Td>{pharmacy.profile.phone}</Td>

                    {ROLE_ID === 2 && <Td>{pharmacy.rcm}</Td>}

                    {(ROLE_ID === 4 || ROLE_ID === 5) && (
                      <>
                        {" "}
                        {pharmacy.farmacia !== null ? (
                          <Td>{pharmacy.farmacia.local_name}</Td>
                        ) : (
                          <Td>Sin Farmacia</Td>
                        )}
                      </>
                    )}
                    <Td>{pharmacy.status ? "Habilitado" : "Deshabilitado"}</Td>
                    <Td align="center" className={classes.buttonStyle}>
                      {loading ? (
                        <RenderLoading />
                      ) : (
                        <>
                          <Tooltip
                            title={`Editar ${LABEL}`}
                            placement="top-start"
                          >
                            <EditIcon
                              color="primary"
                              onClick={() =>
                                setHandleModal({ open: true, pharmacy })
                              }
                            />
                          </Tooltip>
                          {(ROLE_ID === 4 || ROLE_ID === 5 || ROLE_ID === 2) &&
                            (pharmacy.status ? (
                              <Tooltip
                                title={`Desactivar ${LABEL}`}
                                placement="top-start"
                              >
                                <DomainDisabledIcon
                                  color="primary"
                                  onClick={() => disabledItem(pharmacy)}
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={`Activar ${LABEL}`}
                                placement="top-start"
                              >
                                <DomainVerificationIcon
                                  color="primary"
                                  onClick={() => disabledItem(pharmacy)}
                                />
                              </Tooltip>
                            ))}
                          <Tooltip
                            title={`Borrar ${LABEL}`}
                            placement="top-start"
                          >
                            <DeleteIcon
                              color="primary"
                              onClick={() => {
                                setconfirmOpen({
                                  ...confirmOpen,
                                  [`${pharmacy.id}`]: true
                                });
                              }}
                            />
                          </Tooltip>
                          <ConfirmDialog
                            title={
                              pharmacy.profile.first_name +
                              " " +
                              pharmacy.profile.last_name
                            }
                            open={pharmacy ? confirmOpen[pharmacy.id] : false}
                            row={pharmacy}
                            setconfirmOpen={setconfirmOpen}
                            loading={loading}
                            ConfirmDelete={ConfirmDelete}
                          >
                            ¿Estás seguro de Eliminar al {LABEL}
                            {" " +
                              pharmacy.profile.first_name +
                              " " +
                              pharmacy.profile.last_name}{" "}
                            ?
                          </ConfirmDialog>
                        </>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Grid>
        </Box>
      </Box>
      <Snackbar
        open={showAlert.open}
        autoHideDuration={6000}
        onClose={() => setShowAlert({})}
      >
        <Alert onClose={() => setShowAlert({})} severity={showAlert.type}>
          {showAlert.message}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

const useStyles = makeStyles(() => ({
  grow: {
    flexGrow: 1
  },
  buttonStyle: {
    float: "center"
  }
}));
