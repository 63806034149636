import React from "react";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const SelectItems = ({
  action,
  label,
  name,
  values,
  itemsMenu,
  value,
  isBoolean,
  styles,
  isObject
}) => {
  return (
    <div style={{ ...(styles && { ...styles }) }}>
      <Select
        name={name}
        required
        value={value === undefined ? "Seleccionar" : value}
        fullWidth
        onChange={({ target: { value } }) =>
          action({
            ...values,
            [name]: value === "Seleccionar" ? undefined : value
          })
        }
        input={<BootstrapInput />}
      >
        <MenuItem value={0}>{label}</MenuItem>
        {itemsMenu && !isObject
          ? itemsMenu.map((e, i) => {
              return (
                <MenuItem
                  key={i}
                  value={isBoolean ? (e === "Si" ? true : false) : e}
                >
                  {e}
                </MenuItem>
              );
            })
          : isObject
            ? itemsMenu.map(({ value, label }, i) => {
                return (
                  <MenuItem key={i} value={value}>
                    {label}
                  </MenuItem>
                );
              })
            : null}
      </Select>
    </div>
  );
};

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: 5
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #cdcdcd",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);

export default SelectItems;
