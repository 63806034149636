import { connect } from "react-redux";
import SignUpComponent from "./SignUp";
import { userSigUp } from "../../../redux/actions/auth";

const mapStatetoProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    userSignupIn: () => {
      dispatch(userSigUp());
    }
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(SignUpComponent);
