import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core/";
import {
  getModel,
  editModel,
  createModelJson
} from "../../services/service.api";
import { Button, Select, MenuItem, TextField } from "@material-ui/core";
import InputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { maxLength, minLength } from "../utils.auth";
import { responseError } from "../commons/errors/utils";
import RenderLoading from "../commons/loading/loading";
import { validateRut } from "@fdograph/rut-utilities";

const style = {
  error: {
    width: "95%",
    margin: "8px",
    fontSize: "13px",
    color: "rgb(200,0,0)"
  }
};
const FarmaceutaForm = props => {
  const { pharmacy, setHandleModal, setShowAlert, user } = props;
  const [values, setValues] = useState({});
  const [errorsV, setErrorsV] = useState(undefined);
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      if (pharmacy && pharmacy.id) {
        setValues(pharmacy.profile);
      }
    },
    [pharmacy, user]
  );

  const onSubmit = async () => {
    try {
      setLoading(true);

      let res = null;
      if (pharmacy) {
        res = await editModel("peoples", {})(pharmacy.profile.id, values);
      } else {
        res = await createModelJson("peoples/cognito", {})({
          ...values,
          role_id: 4,
          pharmacy_id: user.pharmacy_id
        });
      }
      if (res.id || res.status === 200) {
        setHandleModal({});
        setShowAlert({
          open: true,
          message: "Farmacéutico editado Satisfactoriamente!",
          type: "success"
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setErrorsV(responseError(error));
      setLoading(false);
    }
  };
  const handleInputChangeRut = async rut => {
    if (validateRut(rut) === true) {
      try {
        const { data } = await getModel("peoples", {
          params: { rut }
        });
        if (data && data.length > 0) {
          setValues({ ...data[0], role_id: 5 });
        } else {
          setValues({ rut });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setValues({ rut });
      if (rut.length === 10) {
        setErrorsV(`Rut Invalido`);
      } else {
        setErrorsV();
      }
    }
  };
  const handleSubmitValidate = funct => {
    if (!validateRut(values.rut)) {
      return setErrorsV(`Campo rut es invalid`);
    }
    let err = false;
    [
      { name: "first_name", min: 3, max: 150 },
      { name: "last_name", min: 3, max: 150 },
      { name: "email", min: 3, max: 150 }
    ].forEach(({ name, min, max }) => {
      if (!err) {
        if (maxLength(values[name], max)) {
          err = true;
          return setErrorsV(`Campo ${name} excede cantidad de caracteres`);
        }
        if (minLength(values[name], min) !== true) {
          err = true;
          return setErrorsV(`Campo ${name} es muy corto`);
        }
        err = false;
      }
    });
    if (err) {
      return;
    }

    setErrorsV(undefined);
    funct(values);
  };

  const renderForm = () => (
    <Box boxShadow={5}>
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit(handleSubmitValidate(onSubmit));
        }}
      >
        <Box m={4}>
          <Box textAlign="center">
            <h3>{pharmacy ? "Editar" : "Crear"} farmacéutico</h3>
          </Box>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <InputMask
                maskChar=""
                mask="99999999-k"
                formatChars={{
                  "9": "[0-9]",
                  k: "[0-9,k-k,K-K]"
                }}
                onChange={({ target: { value } }) => {
                  handleInputChangeRut(value);
                  setValues({ ...values, rut: value });
                }}
                inputProps={{
                  minLength: 10,
                  maxLength: 10
                }}
                defaultValue={((pharmacy || {}).profile || {}).rut}
              >
                {() => (
                  <TextField
                    id="rut"
                    label="RUT"
                    name="rut"
                    variant="outlined"
                    margin="dense"
                    type="text"
                    fullWidth
                    autoFocus
                    inputRef={register({
                      required: "RUT Obligatorio.",
                      minLength: {
                        value: 10,
                        message: "Tu rut debe tener 10 caracteres."
                      },
                      maxLength: {
                        value: 10,
                        message: "Tu rut debe tener 10 caracteres."
                      },
                      pattern: {
                        value: /^[0-9]+[-|‐]{1}[0-9kK]{1}/i,
                        message: "Introduzca rut válido"
                      }
                    })}
                  />
                )}
              </InputMask>
              <ErrorMessage
                className="invalid-feedback"
                name="rut"
                as="div"
                errors={errors}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                id="firstName"
                label="Nombre"
                value={(values || {}).first_name || ""}
                name="firstName"
                onChange={({ target: { value } }) =>
                  setValues({ ...values, first_name: value })
                }
                autoComplete="firstName"
                inputRef={register({
                  required: "Nombre Obligatorio."
                })}
              />
              <ErrorMessage
                className="invalid-feedback"
                name="firstName"
                as="div"
                errors={errors}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                id="lastName"
                label="Apellido"
                value={(values || {}).last_name || ""}
                name="lastName"
                onChange={({ target: { value } }) =>
                  setValues({ ...values, last_name: value })
                }
                autoComplete="lastName"
                inputRef={register({
                  required: "Apellido Obligatorio."
                })}
              />
              <ErrorMessage
                className="invalid-feedback"
                name="lastName"
                as="div"
                errors={errors}
              />
            </Grid>
            <Grid item md={12}>
              <Select
                variant="outlined"
                margin="dense"
                required
                fullWidth
                id="gender"
                name="gender"
                label="Sexo"
                value={(values || {}).gender || 0}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, gender: value })
                }
              >
                <MenuItem value={0}>Genero</MenuItem>
                <MenuItem value="Female">Femenino</MenuItem>
                <MenuItem value="Male">Masculino</MenuItem>
              </Select>
            </Grid>
            <Grid item md={6}>
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                id="email"
                label="Email"
                name="email"
                value={(values || {}).email || ""}
                onChange={({ target: { value } }) =>
                  setValues({ ...values, email: value })
                }
                inputRef={register({
                  required: "Email Obligatorio."
                })}
              />

              <ErrorMessage
                className="invalid-feedback"
                name="email"
                as="div"
                errors={errors}
              />
            </Grid>

            <Grid item md={6}>
              {/* {textField({
                name: "phone",
                label: "Telefono",
                action: setValues,
                values,
                size: "small",
                defaultValue: ((pharmacy || {}).profile || {}).phone
              })} */}
              <InputMask
                mask="+56 9 99999999"
                maskChar=" "
                onChange={({ target: { value } }) =>
                  setValues({ ...values, phone: value })
                }
                value={(values || {}).phone || ""}
              >
                {() => (
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="phone"
                    label="Telefono"
                    name="phone"
                    autoComplete="phone"
                    inputRef={register({
                      required: "Números telefónico Obligatorio."
                    })}
                  />
                )}
              </InputMask>
              <ErrorMessage
                className="invalid-feedback"
                name="phone"
                as="div"
                errors={errors}
              />
            </Grid>

            <Grid item md={6}>
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                id="address"
                label="Dirección"
                name="address"
                onChange={({ target: { value } }) =>
                  setValues({ ...values, address: value })
                }
                autoComplete="address"
                inputRef={register({
                  required: "Dirección es Obligatoria.",
                  minLength: {
                    value: 10,
                    message: "Tu dirección debe tener al menos 10 caracteres."
                  }
                })}
                value={(values || {}).address || ""}
              />
              <ErrorMessage
                className="invalid-feedback"
                name="address"
                as="div"
                errors={errors}
              />
            </Grid>
          </Grid>
          <div style={style.error}>{errorsV && errorsV}</div>
          {loading ? (
            <RenderLoading />
          ) : (
            <Button type="submit" fullWidth variant="contained" color="primary">
              {!pharmacy ? "Crear" : "Editar"}
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );

  return renderForm();
};

export default FarmaceutaForm;
