import { Auth } from "aws-amplify";

const AmplifyConfig = {
  Auth: {
    // Amazon Cognito Identity Pool ID
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    // Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION,
    // Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    // Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_APPID,
    // Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false
  },
  API: {
    endpoints: [
      {
        name: "TocApi",
        endpoint: process.env.REACT_APP_API_URL + "/api",
        region: process.env.REACT_APP_AWS_REGION_API,
        custom_header: async headers => {
          return {
            ...headers,
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          };
        }
      },
      {
        name: "TocPing",
        endpoint: process.env.REACT_APP_API_URL,
        region: process.env.REACT_APP_AWS_REGION_API
      }
    ]
  }
};

export default AmplifyConfig;
