import React, { Fragment, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
// import TextField from "@material-ui/core/TextField";
import moment from "moment";
import {
  Grid,
  Toolbar,
  AppBar,
  Box,
  Slide,
  Typography
} from "@material-ui/core/";
import Button from "@material-ui/core/Button";
import AddRecipe from "./AddRecipe";
import Dialog from "@material-ui/core/Dialog";
import { getModel, delModel } from "../../services/service.api";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../../App.css";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// import EditIcon from "@material-ui/icons/Edit";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Snackbar from "@material-ui/core/Snackbar";
import logoTOC from "../../logoTOC.png";
import clsx from "clsx";
import RenderLoading from "../commons/loading/loading";
import MuiAlert from "@material-ui/lab/Alert";
import ConfirmDialog from "../commons/ConfirmDialog/ConfirmDialog";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ListRecipe(props) {
  const { user } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [recipes, setRecipes] = useState([]);
  const [showAlert, setShowAlert] = useState({});
  const [handleModal, setHandleModal] = React.useState({ open: false });
  const [urlPDF, seturlPDF] = useState({});
  const [loading, setLoading] = useState(false);
  const [confirmOpen, setconfirmOpen] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    seturlPDF();
    setOpen(false);
  };

  const getRecipes = async () => {
    const { data } = await getModel("recipes", {
      params: { doctor_id: user.user_id }
    });
    if (data) {
      setRecipes(data);
    } else {
      setRecipes([]);
    }
  };
  const ConfirmDelete = async row => {
    const { id } = row;
    try {
      const receta = recipes.find(i => i.id === id);
      if (receta.signed_status === 3) {
        setShowAlert({
          open: true,
          message: "Recipe esta firmado y no se puede borrar!",
          type: "info",
          id
        });
        return;
      }
      await delModel("recipes")(id);
      setShowAlert({
        open: true,
        message: "Recipe borrado Satisfactoriamente!",
        type: "success",
        id
      });
    } catch (error) {
      setShowAlert({
        open: true,
        message: error.message,
        type: "error",
        id
      });
    }
  };
  const generatePDF = async recipe => {
    try {
      setLoading(true);
      const {
        data: { url }
      } = await getModel("recipes/pdf", { id: recipe.id });

      if (url) {
        seturlPDF(url);
        setHandleModal({ open: true, recipe });
        return setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  useEffect(
    () => {
      getRecipes();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showAlert, user]
  );
  return (
    <Fragment>
      <Box boxShadow={5}>
        <Box p={1} m={4}>
          <Grid>
            <Box display="flex" p={4}>
              <Box p={1} flexGrow={1}>
                <h1>Listado Receta</h1>
              </Box>
              {/* <Box mr={2}>
                <TextField id="busqueda" label="Buscar" />
              </Box> */}
              <Box p={1}>
                <Button
                  style={{ borderRadius: 10 }}
                  color="primary"
                  variant="contained"
                  onClick={handleClickOpen}
                >
                  Generar receta
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid container spacing={4} className={classes.container}>
            {!open ? null : (
              <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
              >
                <AddRecipe
                  handleClose={handleClose}
                  user={user}
                  {...{ setShowAlert }}
                />
              </Dialog>
            )}
          </Grid>
          <Grid>
            <Table>
              <Thead>
                <Tr align="right">
                  <Th>Paciente</Th>
                  <Th>Medicamento</Th>
                  <Th>Fecha</Th>
                  <Th>Código</Th>
                  <Th align="center">Opciones</Th>
                </Tr>
              </Thead>
              <Tbody>
                {recipes.map((row, key) => (
                  <Tr align="right" key={key}>
                    <Td>
                      {row.paciente.profile.first_name}{" "}
                      {row.paciente.profile.last_name}
                    </Td>
                    <Td
                      style={{
                        textAlign: "left",
                        paddingLeft: 15,
                        borderTop: "solid gray 1px"
                      }}
                    >
                      {row.Medicines.map((medicine, i) => (
                        <li key={i}>{medicine.medicine}</li>
                      ))}
                    </Td>
                    <Td>
                      {moment(row.date_broadcast, "YYYY-MM-DD").format(
                        " MMMM D Y"
                      )}
                    </Td>
                    <Td>{row.prescription_number}</Td>
                    <Td align="center">
                      {loading ? (
                        <RenderLoading />
                      ) : (
                        <div>
                          {/* <IconButton
                            disabled={row.signed_status === 1 ? false : true}
                            aria-label="Editar"
                            color="primary"
                          >
                            <EditIcon />
                          </IconButton> */}

                          <IconButton
                            color="secondary"
                            aria-label="Eliminar"
                            // onClick={() => {
                            //   delRecipes(row.id);
                            // }}
                            disabled={row.signed_status === 1 ? false : true}
                            onClick={() => {
                              setconfirmOpen({
                                ...confirmOpen,
                                [`${row.id}`]: true
                              });
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <ConfirmDialog
                            title="Eliminar receta"
                            open={row ? confirmOpen[row.id] : false}
                            row={row}
                            setconfirmOpen={setconfirmOpen}
                            ConfirmDelete={ConfirmDelete}
                          >
                            ¿Estás seguro de Eliminar la Receta
                            {" " +
                              row.prescription_number +
                              " de " +
                              row.paciente.profile.first_name +
                              " " +
                              row.paciente.profile.last_name}{" "}
                            ?
                          </ConfirmDialog>
                          <IconButton
                            color="inherit"
                            aria-label="Generar PDF"
                            onClick={() => {
                              generatePDF(row);
                            }}
                          >
                            <PictureAsPdfIcon />
                          </IconButton>
                        </div>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Grid>
        </Box>
      </Box>
      <Snackbar
        open={showAlert.open}
        autoHideDuration={6000}
        onClose={() => setShowAlert({})}
      >
        <Alert onClose={() => setShowAlert({})} severity={showAlert.type}>
          {showAlert.message}
        </Alert>
      </Snackbar>
      <Dialog
        fullScreen
        open={handleModal.open}
        onClose={() => setHandleModal({})}
        TransitionComponent={Transition}
      >
        <AppBar
          style={{ background: "#140126", paddingTop: "10px" }}
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            {/* <IconButton edge="start" color="inherit" onClick={handlePdfClose} aria-label="close">
              <CloseIcon />
            </IconButton> */}
            <Typography variant="h6" className={classes.title}>
              <img src={logoTOC} alt="logoTOC" />
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => setHandleModal({ ...handleModal, open: false })}
            >
              Cerrar
            </Button>
          </Toolbar>
        </AppBar>

        <div className={classes.containerIframe}>
          <iframe
            className={classes.responsiveIframe}
            src={urlPDF}
            title="pdf-recipe"
          />
        </div>

        <AppBar
          position="fixed"
          color="primary"
          className={classes.appBarBottom}
        >
          <Toolbar>
            <div className={classes.grow} />
          </Toolbar>
        </AppBar>
        <div />
      </Dialog>
    </Fragment>
  );
}
const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  iframe: {
    margin: "0",
    padding: "0",
    height: "90%",
    width: "100%",
    overflow: "auto"
  },
  appBarBottom: {
    top: "auto",
    bottom: 0,
    height: "1%"
  },
  grow: {
    flexGrow: 1
  },
  containerIframe: {
    position: "relative",
    width: "100%",
    overflow: "hidden",
    paddingTop: "100%"
  },
  responsiveIframe: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    border: "none"
  }
}));
