import React, { Fragment } from "react";

import Home from "../components/Home/";

const HomeContainer = () => {
  return (
    <Fragment>
      <Home />
    </Fragment>
  );
};

export default HomeContainer;
