import { connect } from "react-redux";

import { userLogOut } from "../../redux/actions/auth";
import HeaderComponent from "./Header";

const mapStatetoProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => {
      dispatch(userLogOut());
    }
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(HeaderComponent);
